import React from "react";

function HourlyForecast({ data, timezone }) {
	return (
		<div className="overflow-x-scroll flex flex-row space-x-6 py-6 justify-start">
			{data.map((element) => {
				const icon = element.weather[0].icon;
				return (
					<div
						key={element.dt}
						className="flex flex-col py-6 px-6 items-center justify-center bg-white rounded-lg shadow-md"
					>
						<h3 className="text-sm flex flex-nowrap text-center">
							{new Intl.DateTimeFormat("en-US", {
								hour: "numeric",
								timeZone: timezone,
							}).format(element.dt * 1000)}
						</h3>
						<div className="h-20 w-20">
							<img
								src={`http://openweathermap.org/img/wn/${icon}@4x.png`}
								alt="Weather Icon"
							></img>
						</div>
						<h1 className="font-bold text-4xl">
							{Math.floor(element.temp)}&#176;
						</h1>
					</div>
				);
			})}
		</div>
	);
}

export default HourlyForecast;
