const aboutUs = {
	title: "Arkwood Mills",
	paragraphs: [
		"Arkwood Mills was founded in 2021 and started production of soybean meal in 2023. It is located in the small village of Louisville, in Chatham Kent Ontario. Arkwood Mills was founded by a father-son duo who have been poultry farming together since 2012.",
		"Looking to diversify we bought an abandoned grain elevator and repaired and updated it to process local soybeans for soybean meal and soybean oil. Our goal is to help both local soybean producers and local livestock farmers by buying local beans and providing local farmers with a high quality feed ingredient to help grow their animals.",
		"We look forward to helping our community today and in the future."
	],
	landing: ["We are excited to announce the release of our new site! You can easily view Arkwood Mills' cash bids, futures, weather and more."],
	emailList: [
		"philipnywening@arkwoodmills.com",
	],
};

export default aboutUs;
