const aboutUs = {
	title: "Court Farms",
	paragraphs: [
		"Court Farms was started in 1978 by Bill and Sally Court when they moved the family to the area after Bill began his career at the Delhi Research Station(Agriculture and Agri - Food Canada) as a chemist.",
		"In addition to a farming operation, Court Farms also includes a family - owned commercial grain elevator and crop input business located just south of Tillsonburg.",
		"Bruce and Tracey attended the University of Guelph, both earning degrees in agronomy in 1995. Bruce went back to the family farm after graduation and started a custom application business in 1998. Tracey joined the business full time in 2012 after leaving the crop protection industry.",
		"The first phase of the grain elevator was completed in 2007 with an initial capacity of 250,000bu and expanding to the 2 million bushels it currently is today.In addition to merchandizing grain from our Courtland location and the Lofthouse Custom Farming – Corinth location, we purchase off farm grain from producers with their own on-farm storage."
	],
	emailList: [
		"admin@courtfarms.ca"
	],
	joinUs: true,
	showHomePageContact: true,
	showHomePageMap: false,
};

export default aboutUs;
