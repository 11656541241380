const location = {
	address1: "23667 Arkwood Road",
	address2: "Chatham, ON Canada",
	phone: [
		"Phil",
		"519-809-5161",
		"Clarence",
		"519-809-1750",
	],
	email: [
		"Phil",
		"philipnywening@arkwoodmills.com ",
	],
	coordinate: {
		lat: 42.480749,
		lng: -82.122375,
	},
};
export default location;
