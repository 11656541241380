const location = {
    address1: "102245 Grey Rd 5",
    address2: "Owen Sound, Ontario N4K 5N5",
    phone: "519-375-0308",
    email: "sharedonfarms@gmail.com",
    coordinate: {
        lat: 44.51646848341913,  
        lng: -80.98523388185171,
    }
}

export default location;