import React from "react";
import rosendale from "..";

import SilosImage from "../../../assets/images/AddisGrain/addisSilos.jpeg"
import DryerImage from "../../../assets/images/AddisGrain/addisDryer.jpeg"
import TruckImage from "../../../assets/images/backgrounds/addisHomeBackground.jpeg"

function Services() {
    return (
        <div
            className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
            style={{ backgroundColor: rosendale.styles.backgroundColor }}
        >
            <ServicesSection
                img={SilosImage}
                title="Storage"
                description="Our new facility has the capacity to store almost 500,000 bushels and a receiving capacity of 15,000 bushels per hour. Government regulated storage rates have changed recently. If your looking to store your grain instead of selling , we can help. Give us a call for more information."
            />
            <ServicesSection
                img={DryerImage}
                title="Drying"
                description="We offer drying services for corn, soybeans and wheat. We use a GSI top-dry system to provide efficient reliable drying. Please call for drying rates and more information."
                bulletPoints={[

                ]}
            />
            <ServicesSection
                img={TruckImage}
                title="Trucking"
                description="We all know trucks can be hard to find at harvest to keep the combine rolling. We can not guarantee a truck on any given day but we will work with you and our network of local truckers to find the best solution for your farming operation."
                bulletPoints={[
                ]}
            />
        </div>
    );
}

function ServicesSection(props) {
    return (
        <div className="flex flex-col-reverse tablet:flex-row py-4">
            <img
                className="rounded-lg shadow-md h-80  tablet:w-80"
                src={props.img}
                alt={props.title}
            ></img>
            <div className="pb-10 tablet:pb-0 tablet:pl-10">
                <h2
                    className="text-4xl font-bold"
                    style={{ color: rosendale.styles.primaryColor }}
                >
                    {props.title}
                </h2>

                <p className="max-w-2xl text-xl pt-4">{props.description}</p>
                {typeof props.bulletPoints !== "undefined" && (
                    <ul className="pl-10 pt-2">
                        {props.bulletPoints.map((points) => (
                            <li key={points} className="list-disc text-gray-600">
                                {points}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {typeof props.trailingImage !== "undefined" && (
                <img
                    className="pb-10 tablet:pb-0 tablet:pl-10"
                    src={props.trailingImage}
                    alt="test"
                ></img>
            )}
        </div>
    );
}
export default Services;
