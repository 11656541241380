import BusinessLogo from "../../assets/images/logos/sharedonFarms/sharedonFarms-transparent.png";
import HomepageBackground from "../../assets/images/backgrounds/sharedonFarmsBackground.jpg";
import OurStoryImage from "../../assets/images/backgrounds/sharedonFarmsOurStory.jpg";

const styles = {
	mainColor: "#BA0913",
	backgroundColor: "#FFFCF6",
	primaryColor: "#371E1E",
	primaryVariant: "#FFF6F7",
	secondaryColor: "#D17786",
	secondaryVariant: "#D17786",
	surface: "#FFF",
	transparentMainColor: "rgba(186, 9, 19,0.9)",
	homeFontColor: "#FFF",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: HomepageBackground,
	cashBidBackground: HomepageBackground,
	ourStoryImg: OurStoryImage,
};

export default styles;
