const location = {
	address1: "2190 rd 163, St. Marys, ON N4X 1C4",
	address2: "St. Marys, ON Canada",
	phone: [
		"Matthew Giesbrecht",
		"519-872-5578",
	],
	email: [
		"",
		"sciencehillelevators@gmail.com",
	],
	coordinate: {
		lat: 43.299506,
		lng: -81.1997887,
	},
	addresses: [
		{
			title: "",
			address1: "2190 rd 163",
			address2: "St. Marys, ON N4X 1C4",
			coordinate: { lat: 43.299506, lng: -81.1997887 },
		},

	]
};
export default location;
