import appDetails from "./appDetails";
import { Route } from "react-router-dom";
import GrainMarketing from "./grainMarketing";

const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Cash Bids",
			link: "/cashbids",
			show: true,
		},
		{
			title: "Futures",
			link: "/futures",
			show: true,
		},
		{
			title: "News",
			link: "/updates",
			show: false,
		},
		{
			title: "Grain Marketing",
			link: "/grain-marketing",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: "Login",
			link: appDetails.webLink,
			show: true,
		},
	],
	customNavRoute: [
		<Route
			key={"grain-marketing"}
			path="/grain-marketing"
			component={GrainMarketing}
		></Route>,
	],
};

export default navItems;
