import BusinessLogo from "../../assets/images/logos/clearviewGrain/clearview-transparent.png";
import HomepageBackground from "../../assets/images/backgrounds/cvgBackground.png";
import CashBidBackground from "../../assets/images/backgrounds/cvgCashBidBackground.jpeg";

const styles = {
	mainColor: "rgba(0,153,102,1.0)",
	backgroundColor: "#FFFBF4",
	btnTextColor: '#000',
	primaryColor: "#5A876F",
	primaryVariant: "#E8EAE1",
	secondaryColor: "#25496F",
	secondaryVariant: "#FFEAB6",
	surface: "#FFF",
	homeFontColor: "#FFF",
	transparentMainColor: "rgba(0,153,102,0.5)",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: HomepageBackground,
	cashBidBackground: CashBidBackground,
	ourStoryImg: CashBidBackground,
};

export default styles;
