import HomepageBackground from "../../assets/images/backgrounds/lockieLogo.png";

const styles = {
	mainColor: "#156f98",
	backgroundColor: "#F2F2F2",
	primaryColor: "#156f98",
	primaryVariant: "#DCE2F6",
	secondaryColor: "#578A9A",
	secondaryVariant: "#B2DDEB",
	surface: "#FFF",
	transparentMainColor: "rgba(21,111,152,0.9)",
	homeFontColor: "#FFF",
	logo: HomepageBackground,
	isLogoSquared: false,
	homepageBackground: HomepageBackground,
	cashBidBackground: HomepageBackground,
	ourStoryImg: HomepageBackground,
};

export default styles;
