const location = {
	address1: "10004-109 Street",
	address2: "Westlock AB Canada T7P 1P1",
	phone: [
		"Marketing - Kirk",
		"780-349-7034 ext 206",
		"Marketing - Lindsay",
		"780-349-7034 ext 211",
		"Grain Settlements - Stacey",
		"780-349-7034 ext 207",
		"Accounting - Kathy",
		"780-349-7034 ext 205",
		"General Inquiries - John",
		"780-349-7034 ext 204",
		"General Inquiries - Clifford",
		"780-349-7034 ext 202",
	],
	email: [
		"Kirk",
		"kirk@westlockterminals.com",
		"Lindsay",
		"lindsay@westlockterminals.com",
		"Stacey",
		"stacey@westlockterminals.com",
		"Kathy",
		"kathy@westlockterminals.com",
		"John",
		"john@westlockterminals.com",
		"Clifford",
		"clifford@westlockterminals.com"
	],
	coordinate: {
		lat: 54.15702857783079,
		lng: -113.86396310317727,
	},
	hours: [
		{
			type: "Office",
			days: "Monday - Friday",
			hours: "8:00am - 4:30pm Closed from 12:00pm - 12:30pm for lunch",
		},
		{
			type: "Plant",
			days: "Monday - Friday",
			hours: "8:00am - 5:00pm"
		}
	],
};
export default location;
