const aboutUs = {
	title: "Ganaraska Grain",
	paragraphs: [],
	emailList: [
		"cathy@ganaraskagrain.com",
		"rafe@ganaraskagrain.com",
		"margot@ganaraskagrain.com",
	],
	joinUs: true
};

export default aboutUs;
