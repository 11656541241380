import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'applications/json';

const api = axios.create({
    baseURL: process.env.REACT_APP_BFF_ENDPOINT,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, OPTIONS',
    },

});

const Api = {
    getOffers: () => api.get(`api/public-sites/offers/${process.env.REACT_APP_COMPANY_NAME}`, {
        params: { size: 50 },
    }),
    getCme: () => api.get(`api/public-sites/cme/${process.env.REACT_APP_COMPANY_NAME}`),
    getCommodities: () => api.get(`api/public-sites/categories/${process.env.REACT_APP_COMPANY_NAME}`)
}

export default Api;