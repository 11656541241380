const location = {
	address1: "544 Sawmil Rd",
	address2: "Waterloo, ON N2J 4G8",
	phone: "519-744-4941",
	email: "info@rosendalefarms.com",
	coordinate: {
		lat: 43.4968005567008,
		lng: -80.4537806204078,
	},
};

export default location;
