const appDetails = {
	webLink: "https://ganaraska.graindiscovery.com",
	googlePlayLink:
		"https://apps.apple.com/ca/app/ganaraska-grain-direct/id1559471682?uo=4",
	appStoreLink:
		"https://play.google.com/store/apps/details?id=com.graindiscovery.marketplace_flutter.ganaraska",
	landingPage: "home",
	signUpButton: true
};

export default appDetails;
