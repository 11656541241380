const aboutUs = {
	title: "Plover Mills Commodities",
	paragraphs: [],
	landing: ["We are excited to announce the release of our new site! You can easily view Plover Mills' cash bids, futures, weather and more."],
	emailList: [],
	joinUs: false,
	showHomePageContact: true,
	showHomePageMap: false,
};

export default aboutUs;
