const location = {
    address1: "1584 Melwood Drive",
    address2: "Strathroy, ON  N7G 3H5",
    phone: "519-247-3530",
    fax: "519-247-3225",
    email: "grow@ccacres.ca",
    coordinate: {
        lat: 42.883532187659526,  
        lng: -81.7540155238466,
    }
}
export default location;