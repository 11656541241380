import React from "react";
import stLawrenceGrain from "..";
import CropProtectionImage from "../../../assets/images/StLawrenceGrain/products/crop-protection.jpeg";
import SeedsImage from "../../../assets/images/StLawrenceGrain/products/Corn-Seed.jpeg";
import FertilizerImage from "../../../assets/images/StLawrenceGrain/products/fertilizer-tower.jpg";
import SLGSeed from "../../../assets/images/StLawrenceGrain/products/Seed SLG.png";

function Products() {
	return (
		<div
			className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
			style={{ backgroundColor: stLawrenceGrain.styles.backgroundColor }}
		>
			<ProductSection
				img={CropProtectionImage}
				title="Crop Protection"
				description="We have a full line of Crop Inputs, covering all insecticides, fungicides, and crop foliar products. We have crop specialists on staff for customizable crop planning. Crop scouting, soil testing and tissue testing is also available."
			/>
			<ProductSection
				img={SeedsImage}
				title="Seed"
				description="We are proud to represent our brands for all your seed needs. We are a carrier of all your corn, beans, wheat and forage seed, and a licensed seed treating facility for seed wheat and beans."
				trailingImage={SLGSeed}
			/>
			<ProductSection
				img={FertilizerImage}
				title="Fertilizer"
				description="We have a modern Fertilizer Blend tower with over 600 MT of onsite storage"
				bulletPoints={[
					"Have our agronomy team assess your fertilizer needs",
					"Custom Fertilizer Blends",
					"Spreader rental, delivery and custom application available",
					"Our team is ready to support you when you need us",
				]}
			/>
		</div>
	);
}

function ProductSection(props) {
	return (
		<div className="flex flex-col-reverse tablet:flex-row py-4">
			<img
				className="rounded-lg shadow-md h-80  tablet:w-80 object-cover"
				src={props.img}
				alt={props.title}
			></img>
			<div className="pb-10 tablet:pb-0 tablet:pl-10">
				<h2
					className="text-4xl font-bold"
					style={{ color: stLawrenceGrain.styles.primaryColor }}
				>
					{props.title}
				</h2>

				<p className="max-w-2xl text-xl pt-4">{props.description}</p>
				{typeof props.bulletPoints !== "undefined" && (
					<ul className="pl-10 pt-2">
						{props.bulletPoints.map((points) => (
							<li key={points} className="list-disc text-gray-600">
								{points}
							</li>
						))}
					</ul>
				)}
			</div>

			{typeof props.trailingImage !== "undefined" && (
				<img
					className="pb-10 tablet:pb-0 tablet:pl-10"
					src={props.trailingImage}
					alt="test"
				></img>
			)}
		</div>
	);
}
export default Products;
