import React, { Component } from "react";
import postscribe from "postscribe";

import "./FuturesHeader.css";

class Header extends Component {
	componentDidMount() {
		postscribe(
			"#updatedTime",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Last updated:,&link=/markets/?"></script>'
		);
	}

	render() {
		return (
			<div>
				<div className="flex flex-row text-center justify-center px-6">
					<section id="updatedTime" className="text-center"></section>
				</div>
				<p className="text-center pb-10">
					<i>Central time</i>
				</p>
			</div>
		);
	}
}

export default Header;
