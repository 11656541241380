const styles = [
  {
      "featureType": "poi.medical",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.medical",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.medical",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "gamma": "1.50"
          },
          {
              "weight": "1.00"
          },
          {
              "color": "#35414d"
          }
      ]
  },
  {
      "featureType": "poi.medical",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.medical",
      "elementType": "labels.icon",
      "stylers": [
          {
              "color": "#35414d"
          },
          {
              "gamma": "1.50"
          }
      ]
  }
]

export default styles;