const location = {
    address1: "13326 Fourteen Mile Rd RR#4",
    address2: "Denfield ON Canada",
    addresses: [
        {
            title: "Clearview Grain",
            address1: "13326 Fourteen Mile Rd RR#4",
            address2: "Denfield ON Canada",
            coordinate: { lat: 43.11493330667558, lng: -81.40140088271987 },
        },
    ],
    phone: [
        "Greg Walls",
        "519-521-7536",
        "John Walls",
        "519-521-9535",
    ],
    email: "clearviewgrain@isp.ca",
    coordinate: {
        lat: 43.11493330667558,
        lng: -81.40140088271987
    }
}

export default location;