const location = {
	address1: "274 Line 3 S",
	address2: "Shanty Bay, ON L0L 2L0",
	phone: ["(705)-720-0431", "(705)-417-4811 (Text)"],
	email: [
		"Kevin",
		"kevin.addisgrain@gmail.com",
		"Graham",
		"graham.addisgrain@gmail.com",
	],
	coordinate: {
		lat: 44.44869315065027,
		lng: -79.59464613582263,
	},
	hours: [
		{
			type: "Receiving Hours/Hours of Operation",
			days: "",
			hours: "",
		},
		{
			type: "Pre-Harvest",
			days: "Monday - Friday",
			hours: "8:00am - 5:00pm",
		},
		{
			type: "Harvest",
			days: "Monday - Saturday",
			hours: "8:00am - 8:00pm"
		},
		{
			type: "",
			days: "Sunday",
			hours: "8:00am - 6:00pm"
		},
		{
			type: "Call to schedule deliveries outside of posted hours",
			days: "705-720-0431",
			hours: "- Please no grain deliveries before 8:00am to allow emptying of dryer."
		}
	],
};

export default location;
