import styles from './styles';
import location from './location';
import aboutUs from './aboutUs';
import appDetails from './appDetails';
import updates from './updates';

const huron = {
    styles,
    location,
    aboutUs,
    appDetails,
    updates
};

export default huron;