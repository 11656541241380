const location = {
	address1: "43019 Hwy 12 N",
	address2: "Blumenort, MB R0A 0C0",
	phone: [
		"Kathy Hiebert - Office Manager - Natural Proteins Inc.",
		"(204) 355-5040",
		"Art Veenendaal - Trader/Operations - Soybeans, Soymeal, SoyOil",
		"(204) 396-7378",
		"Rene Catellier - Feed Grains, Oats, Corn",
		"(204) 381-0200",
		"Darrell Hiebert - Feed Grains, Oats, Corn",
		"(204) 381-6370",
		"Scott Penner – Feed Grains, Oats, Corn",
		"(204) 403-2025",
		"Keith Barkman - Logistics",
		"(204) 380-2300",
	],
	email: [
		"Kathy Hiebert",
		"kathy@rockypond.ca",
		"General Inquiries",
		"info@naturalproteins.ca",
		"Accounting Inquiries",
		"accounting@naturalproteins.ca",
		"Art Veenendaal",
		"art@rockypond.ca",
		"Rene Catellier",
		"rene@rockypond.ca",
		"Darrell Hiebert",
		"darrell@rockypond.ca",
		"Scott Penner",
		"scott@rockypond.ca"
	],
	teamProfileRPC: [
		{
			name: "Rene Catellier",
			position: "Feed Grains, Oats, Corn",
			phone: "(204) 381-0200",
			email: "rene@rockypond.ca",
            img: "Rene"
		},
		{
			name: "Darrell Hiebert",
			position: "Feed Grains, Oats, Corn",
			phone: "(204) 381-6370",
			email: "darrell@rockypond.ca",
            img: "Darrell"
		},
		{
			name: "Scott Penner",
			position: "Feed Grains, Oats, Corn",
			phone: "(204) 403-2025",
			email: "scott@rockypond.ca",
            img: "Scott"
		},
		{
			name: "Keith Barkman",
			position: "Logistics",
			phone: "(204) 380-2300",
			email: "keith@rockypond.ca",
            img: "Keith"
		},
	],
	teamProfileNPI: [
		{
			name: "Kathy Hiebert",
			position: "Office Manager - Natural Proteins Inc.",
			phone: "(204) 355-5040",
			email: "kathy@rockypond.ca",
            img: 'Kathy'
		},
		{
			name: "Art Veenendaal",
			position: "Trader/Operations - Soybeans, Soymeal, SoyOil",
			phone: "(204) 396-7378",
			email: "art@rockypond.ca",
            img: "Art"
		},
	],
	coordinate: {
		lat: 49.63580753079367,
		lng: -96.68526595532937,
	},
};

export default location;
