const location = {
	address1: "3753 Nauvoo Road",
	address2: "Alvinston ON Canada",
	phone: [
		"Andrew: Trader",
		"519-918-2534",
		"Tannis: Admin Support",
		"519-330-1688",
	],
	email: [
		"Andrew: Trader",
		"andrew.d@groupsolum.com",
		"Tannis: Admin Support",
		"tannis.m@groupsolum.com",
	],
	coordinate: {
		lat: 42.853767097618224,
		lng: -81.87318673742072,
	},
};
export default location;
