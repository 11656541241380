import React, { Component } from "react";
import postscribe from "postscribe";
import "./Futures.css";
import Config from "../../../config/config";

class GrainsOilSeeds extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchingError: false,
		};
	}

	componentDidMount() {
		postscribe(
			"#corn",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Corn:ZC,ZC*1,ZC*2,ZC*3,ZC*4,ZC*5,ZC*6,ZC*7,ZC*8,ZC*9,ZC*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#soybeans",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Soybeans:ZS,ZS*1,ZS*2,ZS*3,ZS*4,ZS*5,ZS*6,ZS*7,ZS*8,ZS*9,ZS*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#wheat",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Wheat:ZW,ZW*1,ZW*2,ZW*3,ZW*4,ZW*5,ZW*6,ZW*7,ZW*8,ZW*9,ZW*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#soybeanMeal",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Soybean Meal:ZM,ZM*1,ZM*2,ZM*3,ZM*4,ZM*5,ZM*6,ZM*7,ZM*8,ZM*9,ZM*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#soybeanOil",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Soybean Oil:ZL,ZL*1,ZL*2,ZL*3,ZL*4,ZL*5,ZL*6,ZL*7,ZL*8,ZL*9,ZL*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#kcWheat",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*K.C. Wheat:KE,KE*1,KE*2,KE*3,KE*4,KE*5,KE*6,KE*7,KE*8,KE*9,KE*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#Minn. Wheat",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Minn. Wheat:MWE,MWE*1,MWE*2,MWE*3,MWE*4,MWE*5,MWE*6,MWE*7,MWE*8,MWE*9,MWE*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#oats",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Oats:ZO,ZO*1,ZO*2,ZO*3,ZO*4,ZO*5,ZO*6,ZO*7,ZO*8,ZO*9,ZO*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#canola",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Canola:RS,RS*1,RS*2,RS*3,RS*4,RS*5,RS*6,RS*7,RS*8,RS*9,RS*10&link=/markets/?"></script>'
		);
	}

	render() {
		return (
			<>
				<div className="flex justify-center flex-wrap">
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="corn"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="soybeans"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="wheat"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="kcWheat"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="Minn. Wheat"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="soybeanMeal"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="soybeanOil"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="oats"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="canola"
					></div>
				</div>
			</>
		);
	}
}

export default GrainsOilSeeds;
