const location = {
	address1: "PO Box 278, 604 Concession 2",
	address2: "Chesley, ON N0G 1L0",
	phone: ["(519)-363-3423", "(519)-363-0386"],
	email: [
		"George - Owner",
		"george@maxwellbeef.ca",
		"Mark - Grain Trader",
		"mark@maxwellbeef.ca",
		"Dayna - Admin",
		"dayna@maxwellbeef.ca",
		"Kim - Admin",
		"kim@maxwellbeef.ca",
	],
	coordinate: {
		lat: 44.29247160198157,
		lng: -81.17792644165827,
	},
	hours: [

	],
};

export default location;
