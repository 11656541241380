import styles from "./styles";
import location from "./location";
import aboutUs from "./aboutUs";
import appDetails from "./appDetails";
import homeUpdates from "./homeUpdates";
import updates from "./updates";
import navItems from "./navItem";

const westlockTerminals = {
	styles,
	homeUpdates,
	location,
	aboutUs,
	appDetails,
	updates,
	navItems,
};

export default westlockTerminals;
