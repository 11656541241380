import BusinessLogo from "../../assets/images/logos/crookedCreek/crookedCreek.png";
import HomepageBackground from "../../assets/images/backgrounds/crookedCreekBackground.jpg";

const styles = {
	mainColor: "rgb(105,105,105)",
	transparentMainColor: "rgba(105,105,105,0.9)",
	backgroundColor: "#FFFEF5",
	btnTextColor: "#000",
	secondaryColor: "#8E6700",
	secondaryVariant: "#F3DEA7",
	primaryColor: "#242423",
	homeFontColor: "#FFF",
	primaryVariant: "#EBEBEB",
	surface: "#FFF",
	isLogoSquared: false,
	logo: BusinessLogo,
	homepageBackground: HomepageBackground,
	cashBidBackground: HomepageBackground,
	ourStoryImg: HomepageBackground,
};

export default styles;
