import React, { Component } from "react";
import postscribe from "postscribe";
import "./Futures.css";
import Config from "../../../config/config";

class Softs extends Component {
	componentDidMount() {
		postscribe(
			"#cocoa",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Cocoa:ECO,ECO*1,ECO*2,ECO*3,ECO*4,ECO*5,ECO*6,ECO*7,ECO*8,ECO*9,ECO*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#coffee",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Coffee:EKC,EKC*1,EKC*2,EKC*3,EKC*4,EKC*5,EKC*6,EKC*7,EKC*8,EKC*9,EKC*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#cotton",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Cotton:ECT,ECT*1,ECT*2,ECT*3,ECT*4,ECT*5,ECT*6,ECT*7,ECT*8,ECT*9,ECT*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#lumber",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Lumber:LBS,LBS*1,LBS*2,LBS*3,LBS*4,LBS*5,LBS*6,LBS*7,LBS*8,LBS*9,LBS*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#sugar-11",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Sugar no. 11:ESB,ESB*1,ESB*2,ESB*3,ESB*4,ESB*5,ESB*6,ESB*7,ESB*8,ESB*9,ESB*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#sugar-16",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Sugar no. 16:ESF,ESF*1,ESF*2,ESF*3,ESF*4,ESF*5,ESF*6,ESF*7,ESF*8,ESF*9,ESF*10,&link=/markets/?"></script>'
		);
	}

	render() {
		return (
			<>
				<div className="flex justify-center flex-wrap">
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="cocoa"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="coffee"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="cotton"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="lumber"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="sugar-11"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="sugar-16"
					></div>
				</div>
			</>
		);
	}
}

export default Softs;
