import BusinessLogo from "../../assets/images/logos/scienceHill/Science-Hill-Logo.png";
import StoryBackground from "../../assets/images/backgrounds/scienceHillStoryBackground.jpg";
import HomepageBackground from "../../assets/images/backgrounds/scienceHillStoryBackground2.jpg";

const styles = {
	mainColor: "#1D374C",
	backgroundColor: "#FFF",
	homeFontColor: "#FFF",
	btnTextColor: "#000",
	primaryColor: "#000",
	primaryVariant: "#FFF7ED",
	secondaryColor: "#000",
	secondaryVariant: "#F3E1CC",
	surface: "#FFF",
	transparentMainColor: "rgba(245, 239, 223,0.5)",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: StoryBackground,
	cashBidBackground: StoryBackground,
	ourStoryImg: HomepageBackground,
};

export default styles;
