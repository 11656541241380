const location = {
    address1: "255 Bell Mill Side Rd",
    address2: "Courtland ON N0J 1E0",
    phone: [
        "",
        "519-842-7090",
    ],
    email: "admin@courtfarms.ca",
    coordinate: {
        lat: 42.7778373155683,
        lng: -80.65336925626326
    }
}

export default location;