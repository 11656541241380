import React from "react";
import Config from "../../config/config";
import SunriseIcon from "../../assets/images/icons/sunrise.svg";
import SunsetIcon from "../../assets/images/icons/sunset.svg";

function CurrentWeather({ data, timezone }) {
	const icon = data.weather[0].icon;

	return (
		<div className="flex flex-col tablet:flex-row flex-wrap justify-between pt-3">
			<div
				className="mb-4 py-6 px-16 tablet:px-6 lg:px-16 rounded-xl space-x-3 shadow-md flex flex-row justify-center items-center"
				style={{ backgroundColor: Config.styles.surface }}
			>
				<img
					className="h-40 w-40"
					src={`http://openweathermap.org/img/wn/${icon}@4x.png`}
					alt="Weather Icon"
				></img>
				<div className="flex flex-col justify-between h-full items-center">
					<h1 className="text-7xl font-bold">{Math.floor(data.temp)}&#176;</h1>
					<div className="flex flex-row">{data.weather[0].main}</div>
					<h1>Feels like: {Math.floor(data.feels_like)}&#176;</h1>
				</div>
			</div>
			<div
				className="mb-4 py-6 px-16 md:px-6 lg:px-16 rounded-xl shadow-md flex flex-col space-y-4 justify-center items-center"
				style={{ backgroundColor: Config.styles.surface }}
			>
				<div className="flex flex-row space-x-10 items-center w-full justify-center">
					<img className="h-16 w-16" src={SunriseIcon} alt="Sunrise Icon"></img>
					<div className="flex flex-col">
						<p>Sunrise:</p>
						<p className="font-bold text-2xl">
							{new Intl.DateTimeFormat("en-US", {
								hour: "numeric",
								minute: "2-digit",
								timeZone: timezone,
							}).format(data.sunrise * 1000)}
						</p>
					</div>
				</div>
				<div className="flex flex-row space-x-10 items-center w-full justify-center">
					<img className="h-16 w-16" src={SunsetIcon} alt="Sunset Icon"></img>
					<div className="flex flex-col">
						<p>Sunset:</p>
						<p className="font-bold text-2xl">
							{new Intl.DateTimeFormat("en-US", {
								hour: "numeric",
								minute: "2-digit",
								timeZone: timezone,
							}).format(data.sunset * 1000)}
						</p>
					</div>
				</div>
			</div>
			<div
				className="mb-4 py-6 px-16 md:px-6 lg:px-16 rounded-lg shadow-md flex flex-row space-x-6 justify-between items-stretch w-full md:w-max"
				style={{ backgroundColor: Config.styles.surface }}
			>
				<div className="flex flex-col justify-between">
					<div>
						<p>Humidity:</p>
						<p className="font-bold text-2xl">{data.humidity}%</p>
					</div>
					<div>
						<p>Pressure:</p>
						<p className="font-bold text-2xl">{data.pressure} kPa</p>
					</div>
				</div>
				<div className="flex flex-col justify-between">
					<div>
						<p>Wind Speed:</p>
						<p className="font-bold text-2xl">{data.wind_speed} km/hr</p>
					</div>
					<div>
						<p>Visibility:</p>
						<p className="font-bold text-2xl">{data.pressure} km</p>
					</div>
				</div>
				<div className="flex flex-col justify-between">
					<div>
						<p>Dew Point:</p>
						<p className="font-bold text-2xl">{data.dew_point}&#176;</p>
					</div>
					<div>
						<p>UVI:</p>
						<p className="font-bold text-2xl">{data.uvi}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CurrentWeather;
