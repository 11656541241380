import axios from "axios";

const apiKey = process.env.REACT_APP_WEATHER_API;
const baseUrl = process.env.REACT_APP_WEATHER_BASE_URL;

const getWeather = async (lat, lon) => {
	try {
		const response = await axios.get(
			`${baseUrl}lat=${lat}&lon=${lon}&exclude=minutely&units=metric&appid=${apiKey}`
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export default getWeather;
