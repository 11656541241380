import HomepageBackground from "../../assets/images/logos/talbotElevator/About Us.jpg";
import Logo from "../../assets/images/logos/talbotElevator/logo.png";
import AnotherImage from "../../assets/images/logos/talbotElevator/IMG-2230.JPG";

const styles = {
  mainColor: "#1B1B1B",
  backgroundColor: "#E1DFDB",
  primaryColor: "#1B1B1B",
  primaryVariant: "#5E5E63",
  secondaryColor: "#1B1B1B",
  secondaryVariant: "#A9A7A2",
  surface: "#FFF",
  transparentMainColor: "rgba(21,111,152,0.9)",
  homeFontColor: "#FFF",
  logo: Logo,
  isLogoSquared: true,
  homepageBackground: HomepageBackground,
  cashBidBackground: AnotherImage,
  ourStoryImg: AnotherImage,
};

export default styles;
