import BusinessLogo from "../../assets/images/logos/addisGrain/addisGrain.png";
import HomepageBackground from "../../assets/images/backgrounds/addisHomeBackground.jpeg";
import StoryBackground from '../../assets/images/backgrounds/addisOurStoryBackground.jpg'

const styles = {
	mainColor: "#014C7D",
	backgroundColor: "#F2F2F2",
	primaryColor: "#014C7D",
	primaryVariant: "#DCE2F6",
	secondaryColor: "#014C7D",
	secondaryVariant: "#B2DDEB",
	surface: "#FFF",
	transparentMainColor: "rgba(21,111,152,0.9)",
	homeFontColor: "#FFF",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: HomepageBackground,
	cashBidBackground: HomepageBackground,
	ourStoryImg: StoryBackground,
	solidNavbar: true
};

export default styles;
