import styles from "./styles";
import location from "./location";
import aboutUs from "./aboutUs";
import appDetails from "./appDetails";
import updates from "./updates";
import navItems from "./navItem";

const ganaraska = {
	styles,
	location,
	aboutUs,
	appDetails,
	updates,
	navItems,
};

export default ganaraska;
