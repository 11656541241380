import React from "react";
import styles from "../styles";

function Seed() {
  return (
    <div className="w-full pt-40 pb-16 px-16 flex flex-col justify-between">
      <h1 className="text-6xl font-bold" style={{ color: styles.primaryColor }}>
        Seed
      </h1>
      <br></br>
      <p>
        We work with our seed suppliers to offer our customers the best possible
        seed for their farm. We take into account soil type, end use, energy
        content, pest and disease resistance, drought tolerance, winter kill and
        more when we are working to match you with the best fit for your acres.
        We are able to offer a wide array of corn, soybean, forage and cover
        crop seeds in multiple variety and seed treatment options.
      </p>
      <br></br>
      <p>Give us a call with any inquiries on price and availability!</p>
    </div>
  );
}

export default Seed;
