
const styles = {
	mainColor: "#007A33",
	backgroundColor: "#FFFBF4",
	btnTextColor: '#FFF',
	primaryColor: "#007A33",
	primaryVariant: "#007A33",
	secondaryColor: "#007A33",
	secondaryVariant: "#007A33",
	surface: "#FFF",
	homeFontColor: "#FFF",
	transparentMainColor: "rgba(0,153,102,0.5)",
	isLogoSquared: true,
};

export default styles;
