const location = {
	address1: "4566 KELLOGG ROAD",
	address2: "PORT HOPE, ON, Canada L1A 3V7",
	phone: [
		"Cathy Crowe",
		"(C) 905-375-3911",
		"Lisa Draj",
		"(O) 905-753-2242",
		"John McWilliam",
		"(O) 905-753-2242",
		"Margot Currelly",
		"(C) 905-376-7246",
		"Rafe Currelly",
		"(C) 905-376-4996",
	],
	email: [
		"Cathy Crowe",
		"cathy@ganaraskagrain.com",
		"Lisa Draj",
		"lisa@ganaraskagrain.com",
		"John McWilliam",
		"john@ganaraskagrain.com",
		"Margot Currelly",
		"margot@ganaraskagrain.com",
		"Rafe Currelly",
		"rafe@ganaraskagrain.com",
	],
	coordinate: {
		lat: 43.99841000953658,
		lng: -78.36448977477133,
	},
};
export default location;
