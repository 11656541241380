import React from "react";

function SignUpButton({ href, backgroundColor, textColor, title }) {
	return (
		<a
			href={href}
			className="rounded-lg shadow-md px-16 py-2 font-bold text-lg hover:text-gray-600 transition-colors duration-300"
			style={{
				background: backgroundColor,
				color: textColor
			}}
		>
			{title}
		</a>
	);
}

export default SignUpButton;
