import BusinessLogo from "../../assets/images/logos/bacresGrain/bacresGrain.png";
import StoryBackground from "../../assets/images/backgrounds/bacresStoryBackground.jpg";
import HomepageBackground from "../../assets/images/backgrounds/bacresHomeBackground.jpg";

const styles = {
	mainColor: "#F5EFDF",
	backgroundColor: "#F6F1EB",
	homeFontColor: "#FFF",
	btnTextColor: "#000",
	primaryColor: "#625543",
	primaryVariant: "#FFF7ED",
	secondaryColor: "#BEAD9A",
	secondaryVariant: "#F3E1CC",
	surface: "#FFF",
	transparentMainColor: "rgba(245, 239, 223,0.5)",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: StoryBackground,
	cashBidBackground: StoryBackground,
	ourStoryImg: HomepageBackground,
};

export default styles;
