const aboutUs = {
	title: "Huron",
	paragraphs: [],
	emailList: [],
	joinUs: false,
	showHomePageContact: false,
	showHomePageMap: false,
};

export default aboutUs;
