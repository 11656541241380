import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Header from './components/header/Header';
import IFrameBids from './components/iframeBids/IframeBids'
import Futures from './pages/futures/IframeFutures';

function App() {
  return (

    <Router>
      <Switch>
        <Route path="/iframe-bids" component={IFrameBids} />
        <Route path="/iframe-futures" component={Futures} />
        <Route path="/" component={Header} />
      </Switch>
    </Router>
  );
}

export default App;
