const location = {
	address1: "12799 Concession Rd 4",
	address2: "Zephyr, ON L0E 1T0",
	phone: "",
	email: "",
	coordinate: {
		lat: 44.20257321841652,
		lng: -79.24049337911963,
	},
};

export default location;
