import appDetails from "./appDetails";
import { Route } from "react-router-dom";
import Feed from "./custom-pages/feed";
import Seed from "./custom-pages/seed";
import Grain from "./custom-pages/grain";

const navItems = {
  nav: [
    {
      title: "Home",
      link: "/home",
      show: true,
    },
    {
      title: "Cash Bids",
      link: "/cashbids",
      show: true,
    },
    {
      title: "Futures",
      link: "/futures",
      show: true,
    },
    {
      title: "Feed",
      link: "/feed",
      show: true,
    },
    {
      title: "Grain",
      link: "/grain",
      show: true,
    },
    {
      title: "Seed",
      link: "/seed",
      show: true,
    },
    {
      title: "Weather",
      link: "/weather",
      show: true,
    },
    {
      title: "Login",
      link: appDetails.webLink,
      show: true,
    },
  ],
  customNavRoute: [
    <Route key={"feed"} path="/feed" component={Feed}></Route>,
    <Route key={"grain"} path="/grain" component={Grain}></Route>,
    <Route key={"seed"} path="/seed" component={Seed}></Route>,
  ],
};

export default navItems;
