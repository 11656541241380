import React from "react";
import styles from "../styles";

function Feed() {
  return (
    <div className="w-full pt-40 pb-16 px-16 flex flex-col justify-between">
      <h1 className="text-6xl font-bold" style={{ color: styles.primaryColor }}>
        Livestock Feed
      </h1>
      <br />
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        Bulk and Customized Diets
      </h3>
      <br />
      <p>
        We work with nutritionists to create the best customized diets for your
        flock or herd. If you have a custom formula that you've been using, we
        are happy to work with you and your team to see what we can do for you.
      </p>
      <br />
      <p>
        Feed is made to fit your schedule for a delivery or pickup time that
        works for you. Bulk truckloads, totes and bags are all available to suit
        your needs and management preferences.
      </p>
      <br />
      <p>
        Talbot is able to make both conventional and specialty diets for your
        flock or herd, such as RWA certified. As well as working with your vet
        scripts as needed.
      </p>
    </div>
  );
}

export default Feed;
