const location = {
  address2: "Aylmer, ON",
  addresses: [
    {
      title: "Main Office",
      address1: "52744 Talbot Line,",
      address2: "Aylmer, ON N5H 2R1",
      coordinate: { lat: 42.769826, lng: -80.87437 },
    },
    {
      title: "Second Elevator",
      address1: "56981 Heritage Line,",
      address2: "Straffordville, ON",
      coordinate: { lat: 42.747103, lng: 80.75767 },
    },
  ],
  phone: "519-7773-2165",
  email: "office@talbotelevators.com",
  teamProfile: [
    {
      name: "Office",
      position: "",
      phone: "519-773-2165",
      email: "office@talbotelevators.com",
    },
    {
      name: "Brett",
      position: "Grain, Feed, and Seed",
      email: "brett@talbotelevators.com",
    },
    {
      name: "Holly",
      position: "Feed",
      email: "holly@talbotelevators.com  ",
    },
  ],
  coordinate: { lat: 42.769826, lng: -80.87437 },
};

export default location;
