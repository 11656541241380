import React, { useEffect, useState } from "react";
import styles from "../styles";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Grain() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [currentTab, setTab] = useState(0);
  const tabs = [
    <DryingCharges width={windowDimensions.width} />,
    <Contracts />,
    <MarketingPlans />,
  ];

  return (
    <div className="w-full pt-40 pb-16 px-16 flex flex-col justify-between">
      <ul className="flex flex-col justify-center tablet:flex-row flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <li className="mr-2">
          <button
            className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
            style={
              currentTab === 0
                ? {
                    backgroundColor: styles.primaryColor,
                    color: "white",
                  }
                : {}
            }
            onClick={() => {
              setTab(0);
            }}
          >
            Drying Charges
          </button>
        </li>
        <li className="mr-2">
          <button
            className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
            style={
              currentTab === 1
                ? {
                    backgroundColor: styles.primaryColor,
                    color: "white",
                  }
                : {}
            }
            onClick={() => {
              setTab(1);
            }}
          >
            Contracts - Explained
          </button>
        </li>
        <li className="mr-2">
          <button
            className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
            style={
              currentTab === 2
                ? {
                    backgroundColor: styles.primaryColor,
                    color: "white",
                  }
                : {}
            }
            onClick={() => {
              setTab(2);
            }}
          >
            Marketing Plans
          </button>
        </li>
      </ul>
      {tabs[currentTab]}
    </div>
  );
}

function DryingCharges({ width }) {
  return (
    <div className="pt-10 flex flex-col md:flex-row">
      <div className="flex flex-col md:w-1/2 items-center">
        <h3
          className="text-3xl font-bold"
          style={{ color: styles.primaryColor }}
        >
          2021 Corn Drying Table
        </h3>
        <br></br>
        {width >= 1024 ? (
          <iframe
            title="2021 Corn Drying Table"
            style={{ width: "100%", height: "720px" }}
            src="https://docs.google.com/document/d/e/2PACX-1vT74iqDk9fur2QsCzqMQRLadvHtGAORufH4aTXA0I88ocncCqQL9bi5aCUHqHGJCAn2QU4ZoxtDfhae/pub?embedded=true"
          ></iframe>
        ) : (
          <a
            className="underline"
            href="https://docs.google.com/document/d/e/2PACX-1vT74iqDk9fur2QsCzqMQRLadvHtGAORufH4aTXA0I88ocncCqQL9bi5aCUHqHGJCAn2QU4ZoxtDfhae/pub"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Document
          </a>
        )}
      </div>
      <div className="flex flex-col md:w-1/2 items-center pt-10 md:pt-0">
        <h3
          className="text-3xl font-bold"
          style={{ color: styles.primaryColor }}
        >
          2021 Soybean Drying Table
        </h3>
        <br></br>
        {width >= 1024 ? (
          <iframe
            title="2021 Corn Drying Table"
            style={{ width: "100%", height: "720px" }}
            src="https://docs.google.com/document/d/e/2PACX-1vT74iqDk9fur2QsCzqMQRLadvHtGAORufH4aTXA0I88ocncCqQL9bi5aCUHqHGJCAn2QU4ZoxtDfhae/pub?embedded=true"
          ></iframe>
        ) : (
          <a
            className="underline"
            href="https://docs.google.com/document/d/e/2PACX-1vT74iqDk9fur2QsCzqMQRLadvHtGAORufH4aTXA0I88ocncCqQL9bi5aCUHqHGJCAn2QU4ZoxtDfhae/pub"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Document
          </a>
        )}
      </div>
    </div>
  );
}

function Contracts() {
  return (
    <div className="pt-10 flex flex-col">
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        Forward Contracts:
      </h3>
      <br></br>
      <p>
        Forward contracts allow you to <strong>lock in your cash price</strong>{" "}
        at any point prior to harvest, sometimes even years in advance, when the
        market reaches a level that you're happy with. This leaves you with no
        risk of a downfall in prices, however if prices do spike higher at a
        certain point in time that will be a missed opportunity.{" "}
        <strong>
          This type of contract is free with no cost to the producer
        </strong>{" "}
        and is by far the most popular type of grain contract used by many
        producers for its benefits.{" "}
      </p>
      <br></br>
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        Target Contracts:
      </h3>
      <br></br>
      <p>
        Target contracts are an agreement between the farmer and the elevator
        that the{" "}
        <strong>
          farmer will sell a set number of bushels to the elevator if the market
          reaches a certain target price within the timeframe agreed upon
        </strong>{" "}
        There is <strong>no fee</strong> for our target contracts. If the
        timeframe is exceeded without the market reaching the target price the
        contract is voided with no result. This type of contract has{" "}
        <strong>zero risk for the farmer. </strong> If the target price is
        reached the bushels are bought by the elevator and it becomes a Forward
        Contract for the price that was targeted.
      </p>
      <br></br>
      <i>
        Take some time and think about your target prices. Fill out and
        send/bring in the sheet below to make it official!
      </i>
      <br></br>
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        Minimum Price Contracts:
      </h3>
      <br></br>
      <p>
        A Minimum Price contract means the elevator agrees that they will
        purchase the contracted bushels from the producer for the{" "}
        <strong>
          contracted price plus any additional increases seen in the market over
          a set period of time.
        </strong>{" "}
        Minus the{" "}
        <strong>fees charged for the ability to stay in the market</strong> and
        see any potential increase. There is no downside risk to the farmer but
        <strong>the fee is charged whether the market increases or not.</strong>
      </p>
      <br></br>
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        Price Later Contracts:
      </h3>
      <br></br>
      <p>
        Price Later contracts mean that the elevator agrees to{" "}
        <strong>
          purchase a certain amount of grain from the farmer at a price to be
          determined at a later date.
        </strong>{" "}
        There is a{" "}
        <strong>fee charged for the privilege of pricing later.</strong> (This
        is not the same as a storage fee). The farmer is at{" "}
        <strong>
          risk of the market price dropping while paying this fee but could also
          benefit from an increase in market prices.
        </strong>{" "}
        In some cases it may be a better option than storage but you should work
        out the numbers with your grain buyer.
      </p>
      <br></br>
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        Basis Contracts:
      </h3>
      <br></br>
      <p>
        A Basis contract is when the producer is only{" "}
        <strong>locks in the basis portion of the cash price</strong> for a set
        number of bushels. The cash price is then{" "}
        <strong>
          calculated with the previously set basis plus the current futures.
        </strong>{" "}
        There is a <strong>fee charged for these contracts.</strong> There is{" "}
        <strong>
          no protection from movement of the futures and no guarantee that the
          basis won't be higher at the time of sale.
        </strong>{" "}
        While this type of contract may have it's benefits to the seller in some
        instances, on a regular day the costs and risk associated may outweigh
        those.
      </p>
      <br></br>
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        Comparing Contract Types:
      </h3>
      <br></br>
      <img alt="Table for comparing all contract types."></img>
    </div>
  );
}

function MarketingPlans() {
  return (
    <div className="pt-10 flex flex-col">
      <h3 className="text-3xl font-bold" style={{ color: styles.primaryColor }}>
        5-Step Farm Marketing Plan
      </h3>
      <br></br>
      <h4 className="text-xl font-medium">
        1. Your average yields and total acres:
      </h4>
      <p>Acres x Average Yield Per Acre = Total Bushels to Sell</p>
      <br></br>
      <h4 className="text-xl font-medium">2. Figure your cost per acre:</h4>
      <p>
        Land prep + Fertilizer + Chemicals + Seed + Land rent or payment +
        Planting + Harvesting + Drying + Price Insurance = Total Cost Per Acre
      </p>
      <br></br>
      <h4 className="text-xl font-medium">
        3. What is your goal profit per acre?
      </h4>
      <br></br>
      <h4 className="text-xl font-medium">
        4. Figure out what your target price should be:
      </h4>
      <p>Cost + Profit per acre goal/ Yield = Target price</p>
      <br></br>
      <h4 className="text-xl font-medium">
        5. Time to put your plan in action!
      </h4>
    </div>
  );
}

export default Grain;
