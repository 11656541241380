import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal, Button } from "react-bootstrap";

import Config from "../../config/config";

const schema = yup.object().shape({
    firstName: yup.string().min(2).max(50).required(),
    lastName: yup.string().min(2).max(50).required(),
    farmName: yup.string().min(2).max(50).required(),
    email: yup.string().min(7).max(80).email().required(),
    phone: yup.number().min(10),
});

const MarketplaceSignUp = () => {
    const [show, setShow] = useState(false);

    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (e) => {
        console.log("e: ", e);

        const firstName = e.firstName;
        const lastName = e.lastName;
        const farmName = e.farmName;
        const email = e.email;
        const phoneNumber = e.phoneNumber;

        document.getElementById("farmerDetailsForm").reset();

        setShow(true);

        await axios.post(
            "https://us-central1-elevator-public-site.cloudfunctions.net/app/requestData",
            {
                firstName,
                lastName,
                farmName,
                email,
                phoneNumber,
                emailList: Config.aboutUs.emailList,
            }
        );
    };

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header style={{ backgroundColor: Config.styles.primaryColor }}>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ color: "white" }}
                    >
                        Thank you!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    Your request has been successffully submitted. We will be in touch
                    with you by email soon.
                    <Button
                        style={{
                            border: "none",
                            backgroundColor: Config.styles.secondaryVariant,
                            color: "black",
                            fontSize: "1em",
                            fontWeight: "450",
                        }}
                        onClick={() => setShow(false)}
                        active
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div
                className="w-full pt-40 pb-16 px-16 flex flex-col md:flex-row justify-between md:space-x-32"
                style={{ backgroundColor: Config.styles.backgroundColor }}
            >
                <div>
                    <h1
                        className="text-4xl font-bold"
                        style={{ color: Config?.styles?.headerFontColor || Config.styles.primaryColor }}
                    >
                        Join our grain marketplace!
                    </h1>
                    <h1
                        className="text-3xl font-bold pt-10 pb-6"
                        style={{ color: Config?.styles?.headerFontColor || Config.styles.primaryColor }}
                    >
                        Features
                    </h1>
                    <ul>
                        <li>
                            <p className="text-lg pb-4">
                                <strong
                                    className="font-bold text-2xl"
                                    style={{ color: Config?.styles?.headerFontColor || Config.styles.primaryColor }}
                                >
                                    Marketplace:
                                </strong>
                                <p style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
                                    &nbsp;View live bids and sell grain instantly during market
                                </p>
                            </p>
                        </li>
                        <li>
                            <p className="text-lg pb-4">
                                <strong
                                    className="font-bold text-2xl"
                                    style={{ color: Config?.styles?.headerFontColor || Config.styles.primaryColor }}
                                >
                                    Offers:
                                </strong>
                                <p style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
                                    &nbsp;Create target offers so you never miss market
                                    opportunities
                                </p>
                            </p>
                        </li>
                        <li>
                            <p className="text-lg pb-4">
                                <strong
                                    className="font-bold text-2xl"
                                    style={{ color: Config?.styles?.headerFontColor || Config.styles.primaryColor }}
                                >
                                    Portfolio:
                                </strong>
                                <p style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
                                    &nbsp;View scale tickets, contracts and settlements in real time
                                </p>
                            </p>
                        </li>
                    </ul>
                </div>
                <div
                    className="shadow-lg items-center flex flex-col p-12 rounded-3xl border-2 border-gray-500"
                    style={{ backgroundColor: Config.styles.surface }}
                >
                    <div className="items-center">
                        <img
                            src={Config.styles.logo}
                            alt="clearview grains"
                            className="max-h-40 max-w-40"
                        />
                    </div>
                    <br />
                    <form
                        action="#"
                        onSubmit={handleSubmit(onSubmit)}
                        id="farmerDetailsForm"
                    >
                        <div className="form-group mb-4">
                            <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                className="form-control"
                                placeholder="First name"
                                {...register("firstName", { required: true })}
                                required
                            />
                        </div>
                        <div className="form-group mb-4">
                            <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                className="form-control"
                                placeholder="Last name"
                                {...register("lastName", { required: true })}
                                required
                            />
                        </div>
                        <div className="form-group mb-4">
                            <input
                                id="farmName"
                                name="farmName"
                                type="text"
                                className="form-control"
                                placeholder="Farm Name"
                                {...register("farmName", { required: true })}
                                required
                            />
                        </div>
                        <div className="form-group mb-4">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                {...register("email", { required: true })}
                                required
                            />
                        </div>
                        <div className="form-group mb-1">
                            <input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                className="form-control"
                                pattern="[1-9]{1}[0-9]{9}"
                                placeholder="Phone Number (Optional)"
                                {...register("phoneNumber", { required: true })}
                            />
                        </div>

                        <br />
                        <div className="flex flex-col justify-center">
                            <button
                                className="rounded-lg shadow-md px-16 py-2 font-bold text-lg text-black hover:text-gray-600 transition-colors duration-300"
                                style={{
                                    backgroundColor: Config?.styles?.headerFontColor || Config.styles.secondaryVariant,
                                    color: Config.styles.btnTextColor,
                                }}
                                type="submit"
                            >
                                Send email
                            </button>
                            <br />
                            <p className="text-sm text-center">
                                Fill in the details and we'll send you an invite to your email
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default MarketplaceSignUp;
