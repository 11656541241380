import React, { useState } from "react";
import GrainsOilSeeds from "./SubClasses/GrainsOilSeeds";
import Livestock from "./SubClasses/Livestock";
import Softs from "./SubClasses/Softs";
import Energies from "./SubClasses/Energies";
import Currencies from "./SubClasses/Currencies";
import Header from "./FuturesHeader";
import Config from "../../config/config";

const Futures = () => {
    const [currentTab, setTab] = useState(0);
    const tabs = [
        <GrainsOilSeeds />,
        <Livestock />,
        <Softs />,
        <Energies />,
        <Currencies />,
    ];

    //since color is coming from Config, we have to declare styling for injected htmls
    const css = `
    .marketview_quoteboard .marketview_title {
        background: ${Config.styles.primaryColor};
    }
    .marketview_quoteboard .marketview_title a {
        color: ${Config.styles.homeFontColor};
    }
  `;

    return (
        <>
            <style>{css}</style>
            <ul className="px-6 flex flex-col justify-center tablet:flex-row flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li className="mr-2">
                    <button
                        className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
                        style={
                            currentTab === 0
                                ? {
                                    backgroundColor: Config.styles.primaryColor,
                                    color: "white",
                                }
                                : {}
                        }
                        onClick={() => {
                            setTab(0);
                        }}
                    >
                        Grains & Oilseeds
                    </button>
                </li>
                <li className="mr-2">
                    <button
                        className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
                        style={
                            currentTab === 1
                                ? {
                                    backgroundColor: Config.styles.primaryColor,
                                    color: "white",
                                }
                                : {}
                        }
                        onClick={() => {
                            setTab(1);
                        }}
                    >
                        Livestock
                    </button>
                </li>
                <li className="mr-2">
                    <button
                        className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
                        style={
                            currentTab === 2
                                ? {
                                    backgroundColor: Config.styles.primaryColor,
                                    color: "white",
                                }
                                : {}
                        }
                        onClick={() => {
                            setTab(2);
                        }}
                    >
                        Softs
                    </button>
                </li>
                <li className="mr-2">
                    <button
                        className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
                        style={
                            currentTab === 3
                                ? {
                                    backgroundColor: Config.styles.primaryColor,
                                    color: "white",
                                }
                                : {}
                        }
                        onClick={() => {
                            setTab(3);
                        }}
                    >
                        Energies
                    </button>
                </li>
                <li>
                    <button
                        className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
                        style={
                            currentTab === 4
                                ? {
                                    backgroundColor: Config.styles.primaryColor,
                                    color: "white",
                                }
                                : {}
                        }
                        onClick={() => {
                            setTab(4);
                        }}
                    >
                        Currencies
                    </button>
                </li>
            </ul>
            {tabs[currentTab]}
            <Header />
        </>
    );
};

export default Futures;
