import React from "react";
import stLawrenceGrain from "..";
import DrainBroImage from "../../../assets/images/StLawrenceGrain/drainbros.png";
import SLGStarchProductImage from "../../../assets/images/StLawrenceGrain/StLawrenceStarchProducts.jpeg";
import Image1 from "../../../assets/images/StLawrenceGrain/history/SLG40s1-1.jpeg";
import Image2 from "../../../assets/images/StLawrenceGrain/history/SLG40s5-1.jpeg";
import Image3 from "../../../assets/images/StLawrenceGrain/history/SLG40s7-1.jpeg";

import Page1 from "../../../assets/images/StLawrenceGrain/history/p1.jpeg";
import Page2 from "../../../assets/images/StLawrenceGrain/history/p2.jpeg";
import Page3 from "../../../assets/images/StLawrenceGrain/history/p3.jpeg";
import Page4 from "../../../assets/images/StLawrenceGrain/history/p4.jpeg";
import Page5 from "../../../assets/images/StLawrenceGrain/history/p5.jpeg";
import Page6 from "../../../assets/images/StLawrenceGrain/history/p6.jpeg";
import Page7 from "../../../assets/images/StLawrenceGrain/history/p7.jpeg";
import Page8 from "../../../assets/images/StLawrenceGrain/history/p8.jpeg";
import Page9 from "../../../assets/images/StLawrenceGrain/history/p9.jpeg";
import Page10 from "../../../assets/images/StLawrenceGrain/history/p10.jpeg";
import Page11 from "../../../assets/images/StLawrenceGrain/history/p11.jpeg";
import Page12 from "../../../assets/images/StLawrenceGrain/history/p12.jpeg";
import Page13 from "../../../assets/images/StLawrenceGrain/history/p13.jpeg";
import Page14 from "../../../assets/images/StLawrenceGrain/history/p14.jpeg";
import Page15 from "../../../assets/images/StLawrenceGrain/history/p15.jpeg";
import Page16 from "../../../assets/images/StLawrenceGrain/history/p16.jpeg";

function AboutUs() {
	var pages = [
		Page1,
		Page2,
		Page3,
		Page4,
		Page5,
		Page6,
		Page7,
		Page8,
		Page9,
		Page10,
		Page11,
		Page12,
		Page13,
		Page14,
		Page15,
		Page16,
	];

	return (
		<div
			className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
			style={{ backgroundColor: stLawrenceGrain.styles.backgroundColor }}
		>
			<h1
				className="text-6xl font-bold"
				style={{ color: stLawrenceGrain.styles.primaryColor }}
			>
				About Us
			</h1>
			<br />
			<p>
				St. Lawrence Grains & Farm Supply Ltd. original site began construction
				in 1980 in Stouffville, Ontario but has historical ties to St. Lawrence
				Starch Company. St. Lawrence Starch has always had a rich history in the
				marketing and production of corn-based products, a tradition that the
				present St. Lawrence Grains & Farm Supply Ltd. has continued. Offering a
				wide range of crop services to support our farmers in Central and
				Eastern Ontario.
			</p>
			<br />
			<div className="flex flex-col-reverse tablet:flex-row-reverse items-center">
				<img
					className="tablet:w-1/2"
					src={DrainBroImage}
					alt="Drain Bros Logo"
				></img>
				<br />
				<p className="tablet:pr-4">
					St. Lawrence Grains & Farm Supply is a Division of Drain Bros.
					Excavating Limited since 2016. Drain Bros., founded in 1971, is the
					leading provider of aggregates, asphalt and professional site
					construction services in the Kawartha's, with a team built of more
					than 400 employees. Good workmanship and exceptional customer service
					are at the heart of everything we do.
				</p>
			</div>
			<br /> <br />
			<h1
				className="text-6xl font-bold"
				style={{ color: stLawrenceGrain.styles.primaryColor }}
			>
				History
			</h1>
			<br />
			<p>
				St. Lawrence Grains & Farm Supply Ltd. current site began construction
				in 1980 but has historical ties to St. Lawrence Starch Company and has a
				rich history in marketing and producing corn based products.
			</p>
			<br />
			<p>
				St. Lawrence Starch Company Limited was a major Canadian manufacturer of
				corn based starch, glucose and feed products, established in 1889 by
				John Gray, Archie Hutchison, Robert Kilgour, Joseph Kilgour and Jessie
				Malcolm and based in Port Credit, Ontario.
			</p>
			<br />
			<div className="flex justify-center">
				<img
					className=""
					src={SLGStarchProductImage}
					alt="SLG Starch Products"
				></img>
			</div>
			<br />
			<p>
				The St. Lawrence Starch Company was a family owned private company
				specializing in the manufacturing of corn based products for over a
				century. Its products, such as Durham corn starch and Jersey Brand
				gluten feed, were produced for the pulp and paper, textile, alcohol,
				grocery/food products and pharmaceutical industry. The Bee Hive Golden
				Corn Syrup brand was a familiar grocery product in many Canadian
				households, and on behalf of this brand the Company sponsored sporting
				events (particularly skiing, figure skating and hockey)
			</p>
			<br />
			<div className="flex flex-row flex-wrap">
				<img className="w-1/3 pr-4" src={Image1} alt="SLG"></img>
				<img className="w-1/3 px-4" src={Image2} alt="SLG"></img>
				<img className="w-1/3 pl-4" src={Image3} alt="SLG"></img>
			</div>
			<br /> <br />
			<div className="grid grid-cols-2 gap-4">
				{pages.map((page) => (
					<img key={page} src={page} alt="page" />
				))}
			</div>
		</div>
	);
}

export default AboutUs;
