import BusinessLogo from "../../assets/images/logos/maxwellGrain/maxwellGrain.png";
import HomepageBackground from "../../assets/images/backgrounds/maxwellGrainHomeBackground.png";
import StoryBackground from '../../assets/images/backgrounds/maxwellGrainStoryBackground.png'

const styles = {
	mainColor: "#015412",
	backgroundColor: "#F2F2F2",
	primaryColor: "#015412",
	primaryVariant: "#B1C7A7",
	secondaryColor: "#015412",
	secondaryVariant: "#B1C7A7",
	surface: "#FFF",
	transparentMainColor: "rgba(21,111,152,0.9)",
	homeFontColor: "#FFF",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: HomepageBackground,
	cashBidBackground: HomepageBackground,
	ourStoryImg: StoryBackground,
	solidNavbar: true
};

export default styles;
