const aboutUs = {
    title: "Cox Farms",
    paragraphs: [
        "Cox Farms, 1592747 Ontario Inc., is owned and operated by the Cox and Vajoczki families.",
        "The business origins date back to the 1920s when the first of the family farms were purchased by Albert Butler, a recent immigrant to Canada looking for a better life away from the coal mines of England.  Five generations later, the agricultural business continues to operate.",
        "The elevator side of the business began in 1987 when Marjorie and William Cox purchased a used grain tank and dryer and moved it to the Troy location. Additional tanks, dryers, and receiving pits were added over the next three decades, growing to its current size of over a million bushels of commercial grain storage, handling corn, soybeans, and soft red wheat.",
        "The family was also heavily involved as one of the founders of IGPC Ethanol and its production facility in Aylmer.",
        "In 2014 the family was recognized as a BMO Farm Family of the Year for Ontario.  In 2016 they received the Osborne Sager Farm Family of the Year in Hamilton Wentworth.The business also provides on-farm purchases and pick-up of grain, as well as custom planting and harvesting."
    ],
    landing:
        ["We are excited to announce the release of our new farmer app! You can easily view live bids, view your open contracts, open storage and create target offers right from your smartphone"],
    emailList: [
        "tomecox67@gmail.com",
    ],
    joinUs: true,
    showHomePageContact: true,
    showHomePageMap: false,
}

export default aboutUs;