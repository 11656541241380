import styles from './styles';
import homeUpdates from './homeUpdates';
import location from './location';
import aboutUs from './aboutUs';
import appDetails from './appDetails';
import updates from './updates';

const clearViewGrains = {
    styles,
    homeUpdates,
    location,
    aboutUs,
    appDetails,
    updates
};

export default clearViewGrains;