const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Futures",
			link: "/futures",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: "Sign Up",
			link: "/signup",
			show: false
		},
		{
			title: "Login",
			link: "https://westlock.graindiscovery.com",
			show: true
		}
	],

};

export default navItems;
