import React from "react";

function DailyForecast({ data }) {
	return (
		<div className="overflow-x-scroll flex flex-row space-x-6 py-6 justify-start">
			{data.map((element) => {
				const icon = element.weather[0].icon;

				return (
					<div
						key={element.dt}
						className="flex flex-col py-6 px-6 items-center justify-center bg-white rounded-lg shadow-md"
					>
						<h3 className="text-baseline flex flex-nowrap text-center">
							{new Intl.DateTimeFormat("en-US", {
								weekday: "short",
							}).format(element.dt * 1000)}
						</h3>
						<div className="h-24 w-24">
							<img
								src={`http://openweathermap.org/img/wn/${icon}@4x.png`}
								alt="Weather Icon"
							></img>
						</div>
						<div className="flex flex-row space-x-4">
							<h1 className="font-bold text-4xl text-center">
								<p className="text-gray-500 font-light text-sm">Low</p>
								{Math.floor(element.temp.min)}&#176;
							</h1>
							<h1 className="font-bold text-4xl text-center">
								<p className="text-gray-500 font-light text-sm">High</p>
								{Math.floor(element.temp.max)}&#176;
							</h1>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default DailyForecast;
