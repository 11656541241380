import BusinessLogo from "../../assets/images/logos/rockyPond/combinedLogo.jpg";
import HomepageBackground from "../../assets/images/backgrounds/rockyPondStoryBackground2.jpg"
import OurStoryBackground from "../../assets/images/backgrounds/rockyPondHomeBackground.jpg";

const styles = {
	mainColor: "bg-[#258CDB]",
	backgroundColor: "#4E4E4E",
	primaryColor: "#258CDB",
	primaryVariant: "#F2F2F2",
	secondaryColor: "#258CDB",
	secondaryVariant: "#258CDB",
	fontSize: "16px",
	surface: "#FFF",
	transparentMainColor: "rgba(101, 183, 37, 0.9)",
	homeFontColor: "#FFF",
	paragraphFontColor: "#FFF",
	logo: BusinessLogo,
	isLogoSquared: false,
	homepageBackground: HomepageBackground,
	cashBidBackground: HomepageBackground,
	ourStoryImg: OurStoryBackground,
	solidNavbar: true,
	headerFontColor: "#FFF",
};

export default styles;
