import React from "react";
import Config from "../../config/config";

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import styles from "./mapStyles";

const Map = () => {
	// To avoid messy rerenders *React has some issues with arrays and other objects

	const mapContainerStyle = {
		width: "100vmax",
		height: "40vmin",
	};

	const libs = ["places"];

	const options = {
		styles: styles,
	};

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: "AIzaSyA3d3YlXbuMChuv_jvyfg5_aUtM1-rUphc",
		libs,
	});
	if (loadError) return "There was an error loading the map";
	if (!isLoaded) return "The map is loading...";
	return (
		<GoogleMap
			mapContainerStyle={mapContainerStyle}
			zoom={9}
			center={Config.location.coordinate}
			options={options}
		>
			<Marker position={Config.location.coordinate} />
		</GoogleMap>
	);
};

function CustomMap(props) {
	// To avoid messy rerenders *React has some issues with arrays and other objects

	const mapContainerStyle = {
		width: "100vmax",
		height: "40vmin",
	};

	const libs = ["places"];

	const options = {
		styles: styles,
	};

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: "AIzaSyA3d3YlXbuMChuv_jvyfg5_aUtM1-rUphc",
		libs,
	});
	if (loadError) return "There was an error loading the map";
	if (!isLoaded) return "The map is loading...";
	return (
		<GoogleMap
			mapContainerStyle={mapContainerStyle}
			zoom={10}
			center={props.coordinate}
			options={options}
		>
			<Marker position={props.coordinate} />
		</GoogleMap>
	);
}

export default Map;
export { CustomMap };
