const aboutUs = {
	title: "Bacres Grain",
	paragraphs: [
		"We are happy to offer a wide selection of grain contracts tailored to suit your marketing needs as a producer. It is the goal of our in-house logistics team to create a seamless experience getting your grain from the field to the end-user with ease. The grain industry is a service and relationship-based business. From quick payment to late receiving hours at harvest, Bacres Grain is committed to building strong and lasting relationships.",
		"With production costs increasing, maximizing profit on the farm takes a sharp pencil. On top of competitive bids, growers can have the confidence in regularly reviewing potential in the marketplace. Placing target orders to take advantage of higher price points is only one example of how we believe we can send more profit back to your operation.",
		"The market moves at a fast pace and knowledge is power. Day or night opportunities come and go. That's why we have implemented knowledgeable and committed staff to help keep producers well informed and have a contact they can know and trust with navigating the markets.",
	],
	emailList: [
		"Andrew.D@groupsolum.com",
	],
	joinUs: true
};

export default aboutUs;
