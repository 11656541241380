import BusinessLogo from "../../assets/images/logos/ganaraskaGrain/ganaraska_logo.png";
import StoryBackground from "../../assets/images/backgrounds/ganaraskaBackground.jpg";

const styles = {
	mainColor: "#F5EFDF",
	backgroundColor: "#FEFFF9",
	homeFontColor: "#FFF",
	btnTextColor: "#000",
	primaryColor: "#699954",
	primaryVariant: "#F4FEE0",
	secondaryColor: "#868F85",
	secondaryVariant: "#99BB9F",
	surface: "#FFF",
	transparentMainColor: "rgba(245, 239, 223,0.5)",
	logo: BusinessLogo,
	isLogoSquared: false,
	homepageBackground: StoryBackground,
	cashBidBackground: StoryBackground,
	ourStoryImg: StoryBackground,
};

export default styles;
