import styles from "./styles";
import homeUpdates from "./homeUpdates";
import location from "./location";
import aboutUs from "./aboutUs";
import appDetails from "./appDetails";
import updates from "./updates";
import navItems from "./navItem";

const clearViewGrains = {
  styles,
  homeUpdates,
  location,
  aboutUs,
  appDetails,
  updates,
  navItems,
};

export default clearViewGrains;
