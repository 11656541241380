import styles from "./styles";
import { useState } from "react";
import { v4 as uuid } from "uuid";

function GrainMarketing() {
	const [currentTab, setTab] = useState(0);
	const tabs = [
		<StoragePolicies />,
		<MarketingBasis />,
		<Conversion />,
		<Grading />,
	];

	return (
		<div
			className="w-full pt-40 pb-16 px-6 flex flex-col justify-between"
			style={{ backgroundColor: styles.backgroundColor }}
		>
			<ul className="flex flex-col justify-center tablet:flex-row flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
				<li className="mr-2">
					<button
						className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
						style={
							currentTab === 0
								? {
										backgroundColor: styles.primaryColor,
										color: "white",
								  }
								: {}
						}
						onClick={() => {
							setTab(0);
						}}
					>
						Storage Policies
					</button>
				</li>
				<li className="mr-2">
					<button
						className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
						style={
							currentTab === 1
								? {
										backgroundColor: styles.primaryColor,
										color: "white",
								  }
								: {}
						}
						onClick={() => {
							setTab(1);
						}}
					>
						Marketing Basics
					</button>
				</li>
				<li className="mr-2">
					<button
						className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
						style={
							currentTab === 2
								? {
										backgroundColor: styles.primaryColor,
										color: "white",
								  }
								: {}
						}
						onClick={() => {
							setTab(2);
						}}
					>
						Conversions
					</button>
				</li>
				<li>
					<button
						className="inline-block py-2 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-white"
						style={
							currentTab === 3
								? {
										backgroundColor: styles.primaryColor,
										color: "white",
								  }
								: {}
						}
						onClick={() => {
							setTab(3);
						}}
					>
						Grading
					</button>
				</li>
			</ul>
			{tabs[currentTab]}
		</div>
	);
}

function StoragePolicies() {
	return (
		<div className="pt-10 space-y-4">
			<h1 className="font-bold text-4xl">Storage Policies</h1>
			<br></br>
			<p>
				Storage Fees start on the following dates for the following commodities:
			</p>
			<ul className="list-disc pl-10">
				<li>
					<span className="font-bold">WHEAT</span> - September 1st of that crop
					year
				</li>
				<li>
					<span className="font-bold">SOYBEAN</span> - November 1st of that crop
					year
				</li>
				<li>
					<span className="font-bold">CORN</span> - December 1st of that crop
					year
				</li>
			</ul>
			<br></br>
			<div className="space-y-4">
				<p>
					All grains can remain in storage until June 30th of the following
					year.
				</p>
				<ul className="list-disc pl-10">
					<li>
						For example, wheat put into storage September 1st, 2016 can remain
						there until June 30th, 2017 at which point it must be sold to
						Ganaraska Grain or shipped back out by the producer.
					</li>
				</ul>
				<br></br>
				<p>
					Grain does not accumulate Storage Fees or Elevation In Handling Fees
					if contracted or sold prior to the applicable Storage Fee start date.
				</p>
				<br></br>
				<p>
					Grain remaining in storage past the Storage Fee start date will be
					subject to a one-time Elevation In Handling Fee of $4/T and storage
					fees will begin to accumulate, at a rate of $0.08 per T/day.
				</p>
				<br></br>
				<p>
					Any stored grain that is shipped from the elevator by the producer
					without being sold to Ganaraska Grain will be subject to an Elevation
					Out Handling Fee of $6/T, plus applicable daily storage fees.
				</p>
				<ul className="list-disc pl-10">
					<li>
						This fee does not apply to stored grain that is sold by the producer
						to Ganaraska Grain.
					</li>
				</ul>
			</div>
			<br></br>
			<br></br>
			<p className="text-sm italic text-red-400 text-center">
				* These storage policies apply to all grains *
			</p>
		</div>
	);
}

function MarketingBasis() {
	return (
		<div className="pt-10 space-y-4">
			<h1 className="font-bold text-4xl">Marketing Basis</h1>
			<br></br>
			<h3 className="underline text-xl font-bold">Cash Sale</h3>
			<ul className="list-disc pl-10">
				<li>
					An agreement between the producer and Ganaraska Grain to pay a certain
					price for a certain quantity and quality of grain.
				</li>
				<li>
					Payment is either immediate or can be deferred for tax planning.
				</li>
			</ul>
			<br></br>
			<h3 className="underline text-xl font-bold">Future Contract</h3>
			<ul className="list-disc pl-10">
				<li>
					An agreement between yourself and Ganaraska Grain to pay a certain
					price for grain to be delivered at a future date.
				</li>
				<li>Useful to lock in a price for new crop commodities.</li>
				<li>
					Useful if the price for grain is attractive to the producer now but
					delivery is not convenient or possible.
				</li>
				<li>
					The producers are at risk to grow or store the grain they contract.
				</li>
				<li>Price risk and gain is passed on.</li>
			</ul>
			<br></br>
			<h3 className="underline text-xl font-bold">Deferred Payment</h3>
			<ul className="list-disc pl-10">
				<li>
					Deferred payments are now permanently allowed under the Grain Act.
				</li>
				<li>
					All deferred payments under 180 days are eligible for declining
					coverage under the insurance provisions of the Grain Financial
					Protection Program.
				</li>
				<br></br>
				<li>
					The dealer will be required to provide the producer requesting the
					deferred payment with written confirmation of:
					<ul className="list-disc pl-10">
						<li>
							The date on which the deferred payment arrangement was entered
							into,
						</li>
						<li>The date or dates on which payment is to be made, and</li>
						<li>
							The amount of each payment and the total amount of all payments.
						</li>
					</ul>
				</li>
				<br></br>
				<li>
					The initial payment for Basis Contracts has changed from 75% to 60%.
				</li>
				<li>
					Elevators/Dealers will have five (5) trading days to pay for Grain
					Sold Out of Storage which has changed the terms of payment from 2:00
					p.m. the next trading day to 2:00 p.m. on the fifth trading day.
				</li>
			</ul>
			<br></br>
			<h3 className="underline text-xl font-bold">Basis Contract</h3>
			<ul className="list-disc pl-10">
				<li>
					An agreement between the producer and Ganaraska Grain to determine the
					difference between the futures price and the net price they will
					receive.
				</li>
				<li>
					Useful if the basis level is attractive, however, the producer
					believe's the futures level will increase.
				</li>
				<li>
					While the basis component of the producer's net price is determined
					the risk and gain of futures price movement is their's.
				</li>
			</ul>
			<br></br>
			<ol className="list-decimal pl-10 space-y-4">
				<li>
					Upon delivery of grain against an open basis contract, the seller is
					entitled to an advance of funds equal to 60% of the cash value of the
					grain using the current value of the designated futures month. Any
					charges owing to Ganaraska will be deducted from the advance in full.
					Once Ganaraska charges are satisfied, any amounts owing by the seller
					for crop loans and advances will be deducted from the gross advance.
				</li>
				<li>
					Ganaraska reserves the right to limit pricing of Basis contracts
					subject to when the Chicago Board of Trade is open and trading.
				</li>
				<li>
					All basis contracts must be priced prior to the first notice day of
					the futures month designated in the contract, unless otherwise stated
					or unless switched.
				</li>
				<li>
					Basis contracts not priced or previously switched will be switched to
					the next futures month at our discretion prior to the first notice day
					of the designated futures month. The next futures month will then
					become the “designated month” for the basis contract. A switching fee
					of $.04 per bushel will be charged at the time of switching.
				</li>
				<li>
					If at any time the gross advance amount exceeds 95% of the cash value
					of the grain using the current value of the designated futures month a
					margin call will be triggered. The margin required to be paid by the
					seller to Ganaraska will reduce the gross advance amount back to 75%
					of the cash value of the grain using the current value of the
					designated futures month.
				</li>
				<li>
					Margin calls will be due within 10 business days from notification by
					invoice of the margin amount owing. If Ganaraska does not receive the
					margin amount within the 10 business days, Ganaraska reserves the
					right to price out the basis contract using the closing Chicago Board
					of Trade futures price of the designated month on any day after the
					10th business day. Any equity or deficit in the contract will be paid
					or invoiced to the seller, respectively.
				</li>
				<li>
					Sellers who have multiple basis contracts outstanding will have their
					contracts combined for the purpose of determining margin owing.
				</li>
			</ol>
			<br></br>
			<h3 className="text-xl font-bold">
				<span className="underline">Cancelled & Unfilled Contract</span>{" "}
				<span className="text-red-300 italic text-sm no-underline">*NEW*</span>
			</h3>
			<ul className="list-disc pl-10">
				<li>
					There will be a $0.25 per bushel (of unfilled portion of total tonnage
					contracted) administration fee.
				</li>
				<br></br>
				<li>
					If the cancelled/unfilled portion of the contract is{" "}
					<span className="font-bold">5% or less of the total tonnage</span>{" "}
					contracted;
					<ul className="list-disc pl-10">
						<li>Remuneration beyond the administration fee will be null.</li>
					</ul>
				</li>
				<br></br>
				<li>
					If the cancelled/unfilled portion of the contract is{" "}
					<span className="font-bold">above 5% of the total tonnage</span>{" "}
					contracted, the contract will be assessed as follows;
					<ul className="list-disc pl-10">
						<li>
							If current spot price is{" "}
							<span className="font-bold">higher than</span> the contracted
							price, the grower is responsible to;
							<ul className="list-disc pl-10">
								<li>Pay Ganaraska Grain the administration fee and;</li>
								<li>Fill the remaining tonnage by some other method, or</li>
								<li>
									Pay Ganaraska Grain the difference between the contracted
									price and the current spot price per tonne of outstanding
									contracted grain.
								</li>
							</ul>
						</li>
						<li>
							If the current spot price is{" "}
							<span className="font-bold">lower than</span> the contracted
							price;
							<ul className="list-disc pl-10">
								<li>
									Remuneration by the grower to Ganaraska Grain beyond the
									administration fee will be null.
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	);
}

function GdTable(props) {
	return (
		<div
			className={
				props.margin
					? "mx-6 tablet:mx-16 relative overflow-x-auto shadow-md rounded-lg"
					: "relative overflow-x-auto shadow-md rounded-lg"
			}
		>
			<table className="table-fixed w-full text-sm text-left text-gray-500">
				<thead
					className="text-xs text-white uppercase"
					style={{ background: styles.primaryColor }}
				>
					<tr>
						{typeof props.headersTop !== "undefined" &&
							props.headersTop.map((header) => (
								<th key={uuid()} scope="col" className="px-6 py-3">
									{header}
								</th>
							))}
					</tr>
				</thead>
				<thead
					className="text-xs text-white uppercase"
					style={{ background: styles.primaryColor }}
				>
					<tr>
						{props.headers.map((header) => (
							<th key={uuid()} scope="col" className="px-6 py-3">
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{props.rows.map((row) => (
						<tr key={uuid()} className="bg-white border-b dark:border-gray-700">
							{row.map((content, index) => {
								return index === 0 ? (
									<th
										key={uuid()}
										scope="row"
										className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap"
									>
										{content}
									</th>
								) : (
									<td key={uuid()} className="px-6 py-4">
										{content}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

function Conversion() {
	return (
		<div className="pt-10 space-y-4">
			<h1 className="font-bold text-4xl">Conversions</h1>
			<br></br>

			<h3 className="underline text-xl font-bold">Grain</h3>
			<GdTable
				headers={["Crop", "Bushel / Tonne", "Pounds / Bushel"]}
				rows={[
					["Wheat", "36.743", "60"],
					["Oats", "64.842", "34"],
					["Barley", "45.93", "48"],
					["Rye", "39.368", "56"],
					["Corn", "39.368", "56"],
					["Soybean", "36.743", "60"],
				]}
			></GdTable>
			<br></br>
			<h3 className="underline text-xl font-bold">Weight</h3>
			<GdTable
				headers={["Base", "Conversion"]}
				rows={[
					["1 lbs", "0.453592 kg"],
					["1 ton (2000 lbs)", "907.1847 kg"],
					["1000 kg (1 tonne)", "2204.6226 lbs"],
				]}
			></GdTable>
			<br></br>
			<h3 className="underline text-xl font-bold">Area & Length</h3>
			<GdTable
				headers={["Base", "Conversion"]}
				rows={[
					["1 acre", "0.4047 hectare"],
					["1 hectare", "2.471 acres"],
					["1 inch", "2.54 cm"],
					["1 foot", "30.46 cm"],
					["1 yard", "0.9144 metre"],
					["1 mile", "1.609 kilometre"],
					["1 kilometre", "0.6214 miles"],
				]}
			></GdTable>
		</div>
	);
}

function Grading() {
	return (
		<div className="pt-10 space-y-4">
			<h1 className="font-bold text-4xl">Grading Parameters & Discounts</h1>
			<br></br>
			<h3 className="text-xl font-bold">
				<span className="underline">Wheat</span>{" "}
				<span className="text-sm font-bold italic text-red-400 text-center">
					*Ganaraska Grain will NOT be accepting wheat treated w/Manipulator*
				</span>
			</h3>
			<ul className="list-disc pl-10">
				<li>Grading according to Canadian Grain Commission Standards</li>
				<li>
					No discount for #2 Wheat, Grade 3 - $10.00 / mt discount, Feed Grade -
					$30 (srw)
				</li>
				<li>Wheat Parameters; Max moisture before shrink & drying - 14.0%</li>
			</ul>
			<h4 className="underline text-lg font-bold pl-6">Protien Premiums</h4>
			<br></br>
			<span className="underline text-md font-bold tablet:pl-16">
				Pool B HRW
			</span>
			<GdTable
				margin={true}
				headers={["Percentage %", "Discount"]}
				rows={[
					["10.5% or less", "NIL"],
					["10.6% to 10.9%", "NIL"],
					["11.0% to 11.9%", "NIL"],
					["12.0% and over", "NIL"],
				]}
			></GdTable>
			<br></br>
			<span className="tablet:pl-16">
				<span className="text-md underline font-bold">Pool C HRS</span>{" "}
				<span className="text-sm">(Premium included)</span>
			</span>
			<GdTable
				margin={true}
				headers={["Percentage %", "Discount"]}
				rows={[
					["11.9%", "$18.00 discount"],
					["12.0% to 12.4%", "$8.00 discount"],
				]}
			></GdTable>
			<br></br>
			<h4 className="underline text-lg font-bold px-6">Grading</h4>
			<GdTable
				margin={true}
				headersTop={["", "Grade #1", "", "Grade #2", "", "Grade #3", ""]}
				headers={[
					"Variety",
					"Test Weight (kg/hl)",
					"% Sprouts",
					"Test Weight (kg/hl)",
					"% Sprouts",
					"Test Weight (kg/hl)",
					"% Sprouts",
				]}
				rows={[
					["SWW", "76", "1", "74", "5", "69", "8"],
					["SRW", "78", "1", "74", "5", "69", "8"],
					["HRW", "78", "1", "74", "5", "69", "8"],
					["HRS", "78", "1", "74", "5", "69", "8"],
				]}
			></GdTable>

			<br></br>
			<h4 className="underline text-md font-bold pl-6">Fusarium Discount</h4>
			<GdTable
				margin={true}
				headers={["Percentage %", ""]}
				rows={[
					["0.0% to 0.9%", "No Discount - Milling"],
					["1.0% to 1.5%", "Grade #3"],
					["1.6% to 5.0%", "Discount - Feed"],
					["5.1% to 10.0% and above", "Sample - Accepted upon inspection"],
				]}
			></GdTable>
			<br></br>
			<br></br>
			<h3 className="underline text-xl font-bold">Soybean</h3>
			<ul className="list-disc pl-10">
				<li>Grading according to Canadian Grain Commission Standards</li>
			</ul>
			<br></br>
			<h4>
				<span className="underline text-md font-bold pl-6 tablet:pl-16">
					Grading Discount
				</span>{" "}
				<span className="text-sm italic">
					Sample Grade Negotiable - Depends on reason for downgrade
				</span>
			</h4>
			<GdTable
				margin={true}
				headers={["Grade", "Discount"]}
				rows={[
					["Grade 2", "No Discount"],
					["Grade 3", "$2.00 / MT"],
					["Grade 4", "$10.00 / MT"],
					["Grade 5", "$15.00 / MT"],
				]}
			></GdTable>
			<br></br>
			<span className="underline text-md font-bold pl-6 tablet:pl-16">
				Green / Damaged Discount
			</span>
			<GdTable
				margin={true}
				headers={["Percentage %", ""]}
				rows={[
					["15.1% to 20.0%", "$18.00 / MT"],
					["20.1% to 25.0%", "$23.00 / MT"],
					["25.1% to 30.0%", "$30.00 / MT"],
				]}
			></GdTable>
			<br></br>
			<span className="underline text-md font-bold pl-6 tablet:pl-16">
				Bean Parameters
			</span>
			<GdTable
				margin={true}
				headers={["Parameters", "Percentage %"]}
				rows={[
					["Max Moisture before Shirnk", "13.0%"],
					["Max Heated", "0.5%"],
					["Max FM - Other grains", "2.0%"],
				]}
			></GdTable>

			<br></br>
			<br></br>
			<h3 className="underline text-xl font-bold">Non GMO Soy</h3>
			<ul className="list-disc pl-10">
				<li>ZERO tolerance for GMO</li>
				<li>No Long Term Storage</li>
				<li>13.0% Moisture is dry - with drying charges starting at 13.1%</li>
			</ul>
			<br></br>
			<GdTable
				margin={true}
				headers={["Parameters", "Percentage %"]}
				rows={[
					["Max Moisture Accepted", "15.0%"],
					["Max Splits", "15.0%"],
					["Max Dockage", "15.0%"],
					["Allowable Corn Dockage", "0.3%"],
				]}
			></GdTable>

			<br></br>
			<br></br>
			<h3 className="text-xl font-bold">
				<span className="underline">2021 IP Soybean - OAC Strive</span>{" "}
				<span className="text-red-300 text-sm italic">*New*</span>
			</h3>
			<ul className="list-disc pl-10">
				<li>ZERO tolerance for GMO</li>
				<li>No Long Term Storage</li>
				<li>14.0% Moisture is dry - with drying charges starting at 14.1%</li>
			</ul>
			<br></br>
			<GdTable
				margin={true}
				headers={["Parameters", "Percentage %"]}
				rows={[
					["Max Moisture Accepted", "15.0%"],
					["Max White Hilum Purity", "99.5%"],
					["Max Splits & Smalls", "15.0%"],
					["Max Green Beans", "2.0%"],
					["Max Dockage & Foreign Material", "1.0%"],
					["Max Weed/Soil/Other Stains", "10.0%"],
					["Max Sclerotia", "1.0%"],
					["Max Damage", "2.0%"],
				]}
			></GdTable>

			<p className="text-center text-sm text-red-400 font-bold">
				*Grain that does not meet these specifications will be accepted as
				conventional soybeans and the IP premium of $3.20 will no longer apply.*
			</p>
			<br></br>
			<br></br>
			<h3 className="underline text-xl font-bold">Corn</h3>
			<ul className="list-disc pl-10">
				<li>Grading according to Canadian Grain Commission Standards</li>
			</ul>
			<br></br>

			<span className="underline text-md font-bold pl-6 tablet:pl-16">
				Grading Discount
			</span>
			<GdTable
				margin={true}
				headers={["Grade", "Discount"]}
				rows={[
					["Grade 2", "No Discount"],
					["Grade 3", "$2.00 / MT"],
					["Grade 4", "$10.00 / MT"],
					["Grade 5", "$15.00 / MT"],
				]}
			></GdTable>

			<br></br>
			<span className="underline text-md font-bold pl-6 tablet:pl-16">
				Corn Parameters
			</span>
			<GdTable
				margin={true}
				headers={["Parameters", "Percentage %"]}
				rows={[["Max Moisture before Drying & Shrinking", "15.5%"]]}
			></GdTable>
		</div>
	);
}
export default GrainMarketing;
