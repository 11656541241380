const aboutUs = {
	title: "Westlock Terminals",
	paragraphs: [
		"Westlock Terminals (NGC) Ltd is an exciting new grain company incorporated on August 23, 2002 and began accepting grain on December 5, 2002. Westlock Terminals is an independently operated grain terminal located in the town of Westlock at the crossroads of highway 44 and highway 18, situated on a C.N.Rail main line.",
		"Westlock Terminals Ltd is owned by over 270 shareholders that are a blend of farmers and business people from the draw area surrounding the terminal. Westlock Terminals provides regular returns to shareholders through the dividend yields of the Class “C” shares as well as the incentive yields of the Class “D” shares. ",
		"Westlock Terminals has utilized capital investment from the community to maintain a profitable operation in Westlock. This commitment will facilitate the viability of local infrastructure in addition to providing investors a positive return on their investment. This commitment is evident in the organizations “Mission Statement” and “Vision Statement” now and for the future.",
		"Mission Statement - To be a profitable, customer driven new generation co-op and pursue sustainable growth. Our success is based on strong leadership and great people.",
		"Vision - THE farmers' co-operative for north central Alberta for grain agri-services"
	],
	emailList: [
		"john@westlockterminals.com",
		"kirk@westlockterminals.com"
	],
	joinUs: true,
    showHomePageContact: true,
};

export default aboutUs;
