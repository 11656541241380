import React, { Component } from "react";
import postscribe from "postscribe";
import "./Futures.css";
import Config from "../../../config/config";

class Energies extends Component {
	componentDidMount() {
		postscribe(
			"#crudeOil",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Crude Oil:ECL,ECL*1,ECL*2,ECL*3,ECL*4,ECL*5,ECL*6,ECL*7,ECL*8,ECL*9,ECL*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#ethanol",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Ethanol:ZE,ZE*1,ZE*2,ZE*3,ZE*4,ZE*5,ZE*6,ZE*7,ZE*8,ZE*9,ZE*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#heatingOil",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Heating Oil:EHO,EHO*1,EHO*2,EHO*3,EHO*4,EHO*5,EHO*6,EHO*7,EHO*8,EHO*9,EHO*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#naturalGas",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Natural Gas:NG,NG*1,NG*2,NG*3,NG*4,NG*5,NG*6,NG*7,NG*8,NG*9,NG*10,&link=/markets/?"></script>'
		);
	}

	render() {
		return (
			<>
				<div className="flex justify-center flex-wrap">
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="crudeOil"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="ethanol"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="heatingOil"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="naturalGas"
					></div>
				</div>
			</>
		);
	}
}

export default Energies;
