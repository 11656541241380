const aboutUs = {
	title: "Sharedon Farms",
	paragraphs: [
		"Sharedon Farms Ltd is owned and operated by the Curry family who started farming in this location in 1980.  In the more than three decades of operation here we have continued to expand into the cash crop business we are today.",
		"In 2001 a single dryer bin was erected on the current site along with a moderate sized storage bin to serve our own need.   As the farm continued to grow so did the drying and storage and in 2011 the pit, elevator and 2 silos were built along with a larger dryer.  It was at that time we became a licensed elevator and began commercially drying/storing/purchasing of crops.  We have continued over the years to increase our storage and currently have the capacity to store 900,000 bu.",
		"Again in 2018 we were excited to be making some more improvements to our facility with a much larger dryer, that has the capacity to dry 7000bu/H of wet crop.  A number of quality testing machines and a second large capacity receiving pit to better handle the volume that our valued customers bring to us. That allows us to receive up to 23000 bu/H of your crop and ours, allowing us to keep the flow of traffic moving quite fast through our location.  No lines to wait in when you should be back in the field.",
		"Come check us out today!",
	],
	emailList: [
		"sharedonfarms@gmail.com",
	],
	joinUs: true,
	showHomePageContact: true,
	showHomePageMap: false,
};

export default aboutUs;
