const appDetails = {
  webLink: "https://talbotelevators.graindiscovery.com",
  googlePlayLink:
    "https://play.google.com/store/apps/details?id=com.graindiscovery.marketplace_flutter.talbot",
  appStoreLink:
    "https://apps.apple.com/ca/app/talbot-elevators/id1551058140?uo=4",
  landingPage: "home",
  signUpButton: true
};

export default appDetails;
