import React, { Component } from "react";
import postscribe from "postscribe";
import "./Futures.css";
import Config from "../../../config/config";

class Livestock extends Component {
	componentDidMount() {
		postscribe(
			"#liveCattle",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Live Cattle:LE,LE*1,LE*2,LE*3,LE*4,LE*5,LE*6,LE*7,LE*8,LE*9,LE*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#feederCattle",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Feeder Cattle:GF,GF*1,GF*2,GF*3,GF*4,GF*5,GF*6,GF*7,GF*8,GF*9,GF*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#leanHogs",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Lean Hogs  :HE,HE*1,HE*2,HE*3,HE*4,HE*5,HE*6,HE*7,HE*8,HE*9,HE*10,&link=/markets/?"></script>'
		);
	}

	render() {
		return (
			<div className="flex justify-center flex-wrap">
				<div
					className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
					style={{ backgroundColor: Config.styles.surface }}
					id="liveCattle"
				></div>
				<div
					className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
					style={{ backgroundColor: Config.styles.surface }}
					id="feederCattle"
				></div>
				<div
					className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
					style={{ backgroundColor: Config.styles.surface }}
					id="leanHogs"
				></div>
			</div>
		);
	}
}

export default Livestock;
