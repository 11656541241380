const location = {
    address1: "",
    address2: "Thorndale, ON",
    addresses: [
        {
            title: "Plover Mills",
            address1: "17231 Plover Mills Rd",
            address2: "Thorndale, ON, N0M 2P0",
            coordinate: { lat: 43.16001202301462, lng: -81.15699133269095 },
        },
        {
            title: "Patterson Grain",
            address1: "23364 Wellburn Rd",
            address2: "St. Marys, ON, N4X 1C6",
            coordinate: { lat: 43.183967692250384, lng: -81.14295746490222 },
        },
    ],
    phone: ["Ian Dann", "519-317-0693", "Mitch McCutcheon", "519-617-0722"],
    email: "heritageacrefarms@quadro.net",
    coordinate: {
        lat: 43.16001202301462,
        lng: -81.15699133269095
    }
}

export default location;