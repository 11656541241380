const location = {
	address1: "274 Line 3 S",
	address2: "Shanty Bay, ON L0L 2L0",
	phone: "(705)-720-0431",
	email: [
		"Kevin",
		"kevin.addisgrain@gmail.com",
		"Graham",
		"graham.addisgrain@gmail.com",
	],
	coordinate: {
		lat: 44.44869315065027,
		lng: -79.59464613582263,
	},
};

export default location;
