import React from "react";

import UpdateTemplate2 from "../../components/update/UpdateTable1b";
import UpdateSimple from "../../components/update/UpdateSimple";

import "./Updates.css";

const Updates = () => {
	return (
		<>
			<div className="w-full pt-40 pb-16 px-16">
				<UpdateTemplate2
					title={"Food Grade Soybeans - pricing:"}
					variety={"S04-K9"}
					huRating={"2650 SCN"}
					hilumColor={"Imperfect Yellow"}
					harvestPremiumPerBushel={"$5.00"}
					characteristics={"Genetics Highly related to S03W4"}
					characteristics2={"High Protein"}
					characteristics3={
						"Stacked Rps1c, 3a genes with good Phytophthora field tolerance"
					}
				/>
				<UpdateTemplate2
					title={"Food Grade Soybeans - pricing:"}
					variety={"S03-W4"}
					huRating={"2600"}
					harvestPremiumPerBushel={"$5.00"}
					characteristics={"Excellent disease package"}
					characteristics2={"High yield and export demand"}
					characteristics3={"Consistent performer on all soil types"}
				/>

				<UpdateTemplate2
					title={"Food Grade Soybeans - pricing:"}
					variety={"S07-M8"}
					huRating={"2725"}
					harvestPremiumPerBushel={"$5.00"}
					characteristics={"Tall plant type with very good standability"}
					characteristics2={`Rps1c with above average Phytophthora root rot field rating`}
					characteristics3={"Developed with white mould tolerance"}
				/>

				<UpdateTemplate2
					title={"Food Grade Soybeans - pricing:"}
					variety={"S10-R2"}
					huRating={"2800 SCN"}
					harvestPremiumPerBushel={"$5.00"}
					characteristics={"Tall bushy plant type that canopies quickly"}
					characteristics2={"Excellent yields on tough acres"}
					characteristics3={"Great root disease protection package"}
				/>

				<UpdateTemplate2
					title={"Food Grade Soybeans - pricing:"}
					variety={"S12-J7"}
					huRating={"2825 SCN"}
					harvestPremiumPerBushel={"$5.00"}
					characteristics={"Performs across a range of all soil types"}
					characteristics2={"Strong soybean cyst nematode protection"}
					characteristics3={"Rps1c/3a Phytophthora gene stack"}
				/>

				<div className="container mx-auto">
					<h4 style={{ textAlign: "center", marginBottom: "2rem" }}>
						<strong>
							Please call regarding other food grade variety premiums and on
							farm pick up premiums
						</strong>
					</h4>
				</div>

				<UpdateSimple
					updateTitle={"Custom Application"}
					updateBlurb={
						"ClearView Grain offers custom services.  A Ford New Holland SP 310 sprayer is available for custom applications.  Custom planting and tillage is also offered.  ClearView Grain has grain trucks available for on farm pick up either from the field or from on farm storage"
					}
				/>
			</div>
		</>
	);
};

export default Updates;
