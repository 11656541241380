const location = {
    address1: "",
    address2: "",
    phone: [],
    email: "",
    coordinate: {
        lat: 42.7778373155683,
        lng: -80.65336925626326
    }
}

export default location;