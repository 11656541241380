const location = {
	address1: "2141 Hwy 5",
	address2: "Troy, ON L0R 2B0",
	phone: [
		"Tom Cox",
		"519-771-4467",
		"Alex Vajoczki",
		"905-906-9119",
		"Joshua Vajoczki",
		"226-208-1422",
		"Ethan Cox",
		"905-923-6223"
	],
	email: [
		"Tom Cox",
		"tomecox67@gmail.com"
	],
	coordinate: {
		lat: 43.26745779166034,
		lng: -80.16343601146443,
	},
};

export default location;
