import React from "react";

const UpdateTemplate2 = (props) => {

  return (
    <>
    <div className="card p-1 mb-5 mx-auto col-11 p-2" 
      style={{borderRadius:"1.2vmax", borderWidth:".22vmax"}}>
      <table className="table table-borderless update-table text-start">
        <thead>
          <tr>
          <th className="table-header" colSpan="4"><strong>{props.title}</strong></th>
          </tr>
        </thead>
        <tbody>
       
          <tr>
          
            <th className="table-header" scope="row">Variety</th>
            <td className="table-data">{props.variety}</td>
          </tr>
          
          <tr>
            <th className="table-header" scope="row">CHU Rating</th>
            <td>{props.huRating}</td>
          </tr>
          <tr>
            <th scope="row">Characteristics</th>
            <td>{props.characteristics}</td>
          </tr>
          <tr>
            <th className="table-header" scope="row" ></th>
            <td className="text-start">{props.characteristics2}</td>
          </tr>
          <tr>
            <th className="table-header" scope="row"></th>
            <td >{props.characteristics3}</td>
          </tr>
          <tr>
            <th className="table-header" scope="row">Harvest Premium Per Bushel</th>
            <td>{props.harvestPremiumPerBushel}</td>
          </tr>
          <tr>
            <th className="table-header" scope="row"></th>
            <td>{props.characteristics2ndLine}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </>
  );
};

export default UpdateTemplate2;
