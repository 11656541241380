import BusinessLogo from "../../assets/images/logos/courtFarms/courtFarms.png";
import HomepageBackground from "../../assets/images/backgrounds/courtFarmsHomeBackground.jpg";
import CashBidBackground from "../../assets/images/backgrounds/courtFarmsOurStoryBackground.jpg";

const styles = {
	mainColor: "rgba(0,153,102,1.0)",
	backgroundColor: "#FFFBF4",
	btnTextColor: '#FFF',
	primaryColor: "#738C4D",
	primaryVariant: "#738C4D",
	secondaryColor: "#738C4D",
	secondaryVariant: "#738C4D",
	surface: "#FFF",
	homeFontColor: "#FFF",
	transparentMainColor: "rgba(0,153,102,0.5)",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: HomepageBackground,
	ourStoryImg: CashBidBackground,
};

export default styles;
