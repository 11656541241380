import styles from './styles';

const homeUpdates = [
  {
    id: 1,
    updateText: (
      <table
        className="table table-borderless update-table"
        style={{ color: `${styles.homeFontColor}` }}
      >
        <thead>
          <tr>
            <th colSpan="4">
              <strong>Food Grade Soybeans - pricing:</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Variety</th>
            <td>S04-K9</td>
          </tr>
          <tr>
            <th scope="row">CHU Rating</th>
            <td>2600 SCN</td>
          </tr>
          <tr>
            <th scope="row">Harvest Premium</th>
            <td>$5.00</td>
          </tr>
          <tr>
            <th scope="row">Characteristics</th>
            <td>
              Genetics Highly related to S03W4, high protein. Stacked Rps1c, 3a genes with good Phytophthora field tolerance
            </td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 2,
    updateText: (
      <table
        className="table table-borderless update-table"
        style={{ color: `${styles.homeFontColor}` }}
      >
        <thead>
          <tr>
            <th colSpan="4">
              <strong>Food Grade Soybeans - pricing:</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Variety</th>
            <td>S03-W4</td>
          </tr>
          <tr>
            <th scope="row">CHU Rating</th>
            <td>2600</td>
          </tr>
          <tr>
            <th scope="row">Harvest Premium</th>
            <td>$5.00</td>
          </tr>
          <tr>
            <th scope="row">Characteristics</th>
            <td>
              Excellent disease package. High yield and export demand. Consistent performer on all soil types
            </td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 3,
    updateText: (
      <table
        className="table table-borderless update-table"
        style={{ color: `${styles.homeFontColor}` }}
      >
        <thead>
          <tr>
            <th colSpan="4">
              <strong>Food Grade Soybeans - pricing:</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Variety</th>
            <td>S07-M8</td>
          </tr>
          <tr>
            <th scope="row">HU Rating</th>
            <td>2725</td>
          </tr>
          <tr>
            <th scope="row">Harvest Premium </th>
            <td>$5.00</td>
          </tr>
          <tr>
            <th scope="row">Characteristics</th>
            <td>
              Tall plant type with very good standability. Rps1c with above average Phytophthora root rot field rating. Developed with white mould tolerance
            </td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 4,
    updateText: (
      <table
        className="table table-borderless update-table"
        style={{ color: `${styles.homeFontColor}` }}
      >
        <thead>
          <tr>
            <th colSpan="4">
              <strong>Food Grade Soybeans - pricing:</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Variety</th>
            <td>S10-R2</td>
          </tr>
          <tr>
            <th scope="row">HU Rating</th>
            <td>2800 SCN</td>
          </tr>
          <tr>
            <th scope="row">Harvest Premium</th>
            <td>$5.00</td>
          </tr>
          <tr>
            <th scope="row">Characteristics</th>
            <td>
              Tall bushy plant type that canopies quickly. Excellent yields on tough acres. Great root disease protection package
            </td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 5,
    updateText: (
      <table
        className="table table-borderless update-table"
        style={{ color: `${styles.homeFontColor}` }}
      >
        <thead>
          <tr>
            <th colSpan="4">
              <strong>Food Grade Soybeans - pricing:</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Variety</th>
            <td>S12-J7</td>
          </tr>
          <tr>
            <th scope="row">HU Rating</th>
            <td>2825 SCN</td>
          </tr>
          <tr>
            <th scope="row">Harvest Premium</th>
            <td>$5.00</td>
          </tr>
          <tr>
            <th scope="row">Characteristics</th>
            <td>
              Performs across a range of all soil types. Strong soybean cyst nematode protection. Rps1c/3a Phytophthora gene stack
            </td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 6,
    updateText: (
      <>
        <h3 style={{ color: `${styles.homeFontColor}` }}>
          <strong>Custom Application</strong>
        </h3>
        <h5 style={{ color: `${styles.homeFontColor}` }}>
          ClearView Grain offers custom services. A  Ford New Holland SP 310 sprayer
          is available for custom applications. Custom
          planting and tillage is also offered. ClearView Grain has grain trucks
          available for on farm pick up either from the field or from on farm
          storage
        </h5>
      </>
    ),
  },
  {
    id: 7,
    updateText: (
      <>
        <h5 style={{ color: `${styles.homeFontColor}` }}>
          Please call regarding other food grade variety premiums and on farm pick up premiums
        </h5>
      </>
    ),
  },
];

export default homeUpdates;