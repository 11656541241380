const location = {
	address1: "RR 3 655 Concession #14",
	address2: "Walkerton, Ontario N0G 2V0",
	phone: "519-392-8187",
	email: "rkmgrain@gmail.com",
	coordinate: {
		lat: 44.073365640196826,
		lng: -81.29973161990853,
	},
};

export default location;
