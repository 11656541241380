const aboutUs = {
    title: "Maxwell Grain Farms",
    paragraphs: [
        "Over a century old, Maxwell Beef Farms has developed into a cash crop / feed lot farms in the local Chesley area.Growing a little bit over each decade, Maxwell's history shows how farming has changed since the 1900 mixed farms to today's agricultural society.",
        "Maxwell Farms all started in the year 1900 where George Maxwell purchased the original 100 acres of land from Henry Manley, which many will know as the century farm or Lot 11, Concession 3. The Manley family originally purchased the land from the Crown in 1850.",
        "His son, George Edward Maxwell continued to farm and purchased 50 more acres on Lot 8, Concession 3. When his son was old enough(George Robert Maxwell), they purchased more land during the 1950's, This land is now the home farm for the Maxwell Family.",
        "From a mixed family farm, the Maxwell Farm went to a strictly beef farm with the third generation farmer, George Robert Maxwell.",
        `Throughout this time the old workhorse was soon replaced with the Maxwell Family's first tractor an "Oliver 70". The barns and stables were being converted and sometimes replaced with silos, both pit and tower, and outdoor feeding systems.`,
        `In 1981 the farm became an official company with the fourth generation George Duncan Maxwell, and the farm became known as Maxwell Beef Farms Ltd. With the farm becoming an official business, expansions continued with a grain elevator being built in the mid 1980's along with expansions to the feed lot throughout the 90’s and grain elevator improvements/expansions over the last 40 years.`,
        `Maxwell Beef Farms today now goes by Maxwell Grain Farms Inc. and is a cash crop elevator business that accepts a wide variety of wheat, soybeans, and corn. Even though a few extra hands help out, the farm is still known to the Maxwell's as the family farm.`
    ],
    emailList: [
        "george@maxwellbeef.ca",
        "mark@maxwellbeef.ca",
        "dayna@maxwellbeef.ca",
        "kim@maxwellbeef.ca"
    ],
    landing: ["We are excited to announce the release of our new site! You can easily view Maxwell Farms' cash bids, futures, weather and more."],
    showHomePageContact: true,
    joinUs: true
}

export default aboutUs;