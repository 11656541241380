import React from "react";
import rosendale from "..";

import WaterlooLogo from '../../../assets/images/Rosendale/waterlooServicesLogo.png'
import WaterlooCropBG2 from '../../../assets/images/Rosendale/waterlooServicesBG2.jpg'
import PioneerLogo from '../../../assets/images/Rosendale/PioneerLogo.jpeg'
import CropInputs from '../../../assets/images/Rosendale/cropinputs.PNG'
import Application from '../../../assets/images/Rosendale/Application.jpg'
import CropApplication from '../../../assets/images/Rosendale/cropprotection.png'

function waterlooCropServices() {
    return (
        <div
            className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
            style={{ backgroundColor: rosendale.styles.backgroundColor }}
        >
            <WaterlooCropServicesSection
                img={WaterlooLogo}
                title="Waterloo Crop Services"
                description="Waterloo Crop Services has been involved in supplying crop inputs since 1982. We are a full service independent retail outlet located east of Waterloo.  There are 3 Certified Crop Advisors (CCA) on staff to help with crop input decisions. We have access to a full line of crop protection products, seed and we offer custom application for both crop protection and fertilizer application. Crop scouting and nutrient management packages are also available."
            />
            <WaterlooCropServicesSection
                img={PioneerLogo}
                title="Pioneer Hi-Bred Sales Agency"
                description="We have been a Pioneer dealer for over 50 years. Please call us for product information or visit the Pioneer website at www.pioneer.com/growingpoint for good information on agronomy, animal nutrition, feed management, and decision-making tools"
                bulletPoints={[

                ]}
            />
            <WaterlooCropServicesSection
                img={CropInputs}
                title="Fertilizer"
                description="Waterloo Crop Service upgraded the ground blender to a modern fertilizer tower in 2017. With the busy spring rush, timing is everything. Having the ability to blend multiple products efficiently and accurately allows farmers to spend more time in the field."
                bulletPoints={[
                ]}
            />
            <WaterlooCropServicesSection
                img={Application}
                title="Application"
                description="John Deere 600R with Amazone, John Deere Airflow, pull-type spreaders.  We are proud to work with Bloomingdale farms who provides application with their John Deere/ Haggie sprayers."
                bulletPoints={[
                    "Variable Rate Application: With the high cost of fertilizer, we have to find more economical ways to apply the fertilizer to maximize yield and our return on fertilizer investment. There are many ways of creating application zones. Some of the most common are satellite imagery for plant health, historical yield data, and grid soil sampling. Reach out to a crop advisor to discuss farm-by-farm options."
                ]}
            />
            <WaterlooCropServicesSection
                img={CropApplication}
                title="Crop Protection"
                description="We carry a full line up of crop production products from Corteva, Syngenta, Bayer, BASF, Nufarm, and many other companies."
                bulletPoints={[
                ]}
            />
            <WaterlooCropServicesSection
                img={WaterlooCropBG2}
                title="Nutrient Management"
                description="Nutrient management is here to stay. All farms in Ontario will eventually need to do a Nutrient Management Plan/Strategy. Presently, all farms that generate more than 300 Nutrient Units per year and all new operations generating more than 5 Nutrient Units are required to have a Nutrient Management plan approved by July 1, 2005. All other generators and users of nutrients will be phased in for their Nutrient Management requirements."
                bulletPoints={[
                    `Check out the OMAF website for more details on Nutrient Management.
                    http://www.omafra.gov.on.ca/english/agops/`,
                    "Contact us for more information or if your need a Nutrient Management Plan done.",
                ]}
            />
        </div>
    );
}

function WaterlooCropServicesSection(props) {
    return (
        <div className="flex flex-col-reverse tablet:flex-row py-4">
            <img
                className="rounded-lg shadow-md h-80  tablet:w-80"
                src={props.img}
                alt={props.title}
            ></img>
            <div className="pb-10 tablet:pb-0 tablet:pl-10">
                <h2
                    className="text-4xl font-bold"
                    style={{ color: rosendale.styles.primaryColor }}
                >
                    {props.title}
                </h2>

                <p className="max-w-2xl text-xl pt-4">{props.description}</p>
                {typeof props.bulletPoints !== "undefined" && (
                    <ul className="pl-10 pt-2">
                        {props.bulletPoints.map((points) => (
                            <li key={points} className="list-disc text-gray-600">
                                {points}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {typeof props.trailingImage !== "undefined" && (
                <img
                    className="pb-10 tablet:pb-0 tablet:pl-10"
                    src={props.trailingImage}
                    alt="test"
                ></img>
            )}
        </div>
    );
}
export default waterlooCropServices;
