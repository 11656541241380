import React, { useEffect, useRef, useState } from "react";
import getWeather from "../../services/Weather";

import Config from "../../config/config";

import HourlyForecast from "./HourlyForcast";
import DailyForecast from "./DailyForecast";
import CurrentWeather from "./CurrentWeather";

function Weather() {
	const dailyButtonRef = useRef();
	const hourlyButtonRef = useRef();
	const [isHourly, setIsHourly] = useState(true);
	const [isDaily, setIsDaily] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const [currentData, setCurrentData] = useState({});
	const [hourlyData, setHourlyData] = useState([]);
	const [dailyData, setDailyData] = useState([]);
	const [timezone, setTimezone] = useState("America/Nipigon");

	function toggleButtonStyle() {
		dailyButtonRef.current.classList.toggle("text-gray-400");
		dailyButtonRef.current.classList.toggle("text-black");
		dailyButtonRef.current.classList.toggle("font-bold");
		dailyButtonRef.current.classList.toggle("font-medium");

		hourlyButtonRef.current.classList.toggle("text-gray-400");
		hourlyButtonRef.current.classList.toggle("text-black");
		hourlyButtonRef.current.classList.toggle("font-bold");
		hourlyButtonRef.current.classList.toggle("font-medium");
	}

	function switchToDaily() {
		toggleButtonStyle();
		setIsHourly(false);
		setIsDaily(true);
	}

	function switchToHourly() {
		toggleButtonStyle();
		setIsHourly(true);
		setIsDaily(false);
	}

	useEffect(() => {
		async function fetchCurrentWeather() {
			setIsLoading(true);
			try {
				const { current, hourly, daily, timezone } = await getWeather(
					Config.location.coordinate.lat,
					Config.location.coordinate.lng
				);
				if (!current || !hourly || !daily) {
					throw new Error("Unable to fetch data");
				}
				if (current) setCurrentData(current);
				if (hourly) setHourlyData(hourly);
				if (daily) setDailyData(daily);
				if (timezone) setTimezone(timezone);
			} catch (error) {
				console.log(error);
			}
			setIsLoading(false);
		}

		fetchCurrentWeather();
	}, []);

	return (
		<div
			className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
			style={{ backgroundColor: Config.styles.backgroundColor }}
		>
			{isLoading && (
				<div className="p-64 flex justify-center spinner-container">
					<div className="loading-spinner"></div>
				</div>
			)}
			{!isLoading && (
				<div>
					<div className="flex flex-col tablet:flex-row w-full justify-between md:space-x-10 items-start tablet:items-center md:items-end">
						<h3 className="text-xl tablet:text-3xl font-bold tablet:pb-0" style={{ color: `${Config?.styles?.headerFontColor || Config?.styles?.primaryColor || '#000'}` }}>
							Current Condition:&nbsp;
							<span className="text-xl tablet:text-3xl font-normal">
								{Config.location.address2}
							</span>
						</h3>
						<p className="text-baseline" style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
							{new Intl.DateTimeFormat("en-US", {
								day: "numeric",
								weekday: "long",
								month: "short",
								year: "numeric",
								hour: "2-digit",
								minute: "numeric",
								timeZone: timezone,
							}).format(currentData.dt * 1000)}{" "}
							{timezone}
						</p>
					</div>
					{!isLoading && (
						<CurrentWeather data={currentData} timezone={timezone} />
					)}
					<h3 className="text-xl tablet:text-3xl font-bold" style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
						Forecast:&nbsp;&nbsp;&nbsp;
						<button onClick={switchToHourly}>
							<span
								ref={hourlyButtonRef}
								className="text-lg tablet:text-xl font-bold text-black"
								style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}
							>
								Hourly&nbsp;
							</span>
						</button>
						<span className="text-lg tablet:text-xl">|</span>
						<button onClick={switchToDaily}>
							<span
								ref={dailyButtonRef}
								className="text-lg tablet:text-xl font-medium text-gray-400"
								style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}

							>
								&nbsp;Daily
							</span>
						</button>
					</h3>
					{isHourly && <HourlyForecast data={hourlyData} timezone={timezone} />}
					{isDaily && <DailyForecast data={dailyData} />}
				</div>
			)}
		</div>
	);
}

export default Weather;
