import appDetails from "./appDetails";
import { Route } from "react-router-dom";
import Products from "./custom-pages/ip-edible-beans";

const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Cash Bids",
			link: "/cashbids",
			show: true,
		},
		{
			title: "Futures",
			link: "/futures",
			show: true,
		},
		{
			title: "IP and Edible Beans",
			link: "/ip-edible-beans",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: process.env.REACT_APP_BIDS_LOGIN === "true" ? "Bids Login" : "Login",
			link: appDetails.webLink,
			show: false,
		},
		{
			title: 'Prograin',
			link: 'https://www.prograin.ca',
			show: true,
			external: true,
		},
		{
			title: 'Ontario Plot Results',
			link: 'https://www.gocrops.ca',
			show: true,
			external: true,
		}
	],
	customNavRoute: [
		<Route key={"ip-edible-beans"} path="/ip-edible-beans" component={Products}></Route>,
	],
};

export default navItems;
