import { Route } from "react-router-dom";
import Services from "./custom-pages/Services";
import appDetails from "./appDetails";
const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Cash Bids",
			link: "/cashbids",
			show: true,
		},
		{
			title: "Futures",
			link: "/futures",
			show: true,
		},
		{
			title: "Services",
			link: "/services",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: "Login",
			link: appDetails?.webLink,
			show: true
		}
	],
	customNavRoute: [
		<Route key={"services"} path="/services" component={Services}></Route>,
	],
};

export default navItems;
