import React, { Component } from "react";
import postscribe from "postscribe";
import "./Futures.css";
import Config from "../../../config/config";

class Currencies extends Component {
	componentDidMount() {
		postscribe(
			"#euroDollar",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Euro Dollar:GE,GE*1,GE*2,GE*3,GE*4,GE*5,GE*6,GE*7,GE*8,GE*9,GE*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#euroCurrency",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Euro Currency:6E,6E*1,6E*2,6E*3,6E*4,6E*5,6E*6,6E*7,6E*8,6E*9,6E*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#australianDollar",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Australian Dollar:6A,6A*1,6A*2,6A*3,6A*4,6A*5,6A*6,6A*7,6A*8,6A*9,6A*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#canadianDollar",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Canadian Dollar:6C,6C*1,6C*2,6C*3,6C*4,6C*5,6C*6,6C*7,6C*8,6C*9,6C*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#britishPound",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*British Pound:6B,6B*1,6B*2,6B*3,6B*4,6B*5,6B*6,6B*7,6B*8,6B*9,6B*10,&link=/markets/?"></script>'
		);
		postscribe(
			"#japaneseYen",
			'<script src="https://fj.qtmarketcenter.com/js/snapshot.php?symbol=*Japanese Yen:6J,6J*1,6J*2,6J*3,6J*4,6J*5,6J*6,6J*7,6J*8,6J*9,6J*10,&link=/markets/?"></script>'
		);
	}

	render() {
		return (
			<>
				<div className="flex justify-center flex-wrap">
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="canadianDollar"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="euroDollar"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="euroCurrency"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="australianDollar"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="britishPound"
					></div>
					<div
						className="m-5 w-[300px] tablet:w-[400px] shadow-md rounded-xl"
						style={{ backgroundColor: Config.styles.surface }}
						id="japaneseYen"
					></div>
				</div>
			</>
		);
	}
}

export default Currencies;
