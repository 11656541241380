import React from "react";

const UpdateSimple = (props) => {

  return (
    <>
    <div className="card p-1 mb-5 mx-auto col-11 update-table p-2" 
      style={{borderRadius:"1.2vmax", borderWidth:".22vmax"}}>
      <h5><strong>{props.updateTitle}</strong></h5>
      <p>{props.updateBlurb}</p>
      </div>
    </>
  );
};

export default UpdateSimple;
