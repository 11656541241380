const aboutUs = {
    title: "Crooked Creek Acres",
    paragraphs: [
        `Crooked Creek Acres is a family-owned bean processing plant and elevator located in Strathroy, Ontario, mid-way between London and Sarnia. It is owned and operated by Rob and Lori Langford.`,
        `In 2009, they decided to take a risk and build a soybean processing plant, and the dream of Crooked Creek Acres began.  CCA is a CIPRS/ HACCP (Canadian Identity Pre- served Recognition System / Hazard Analysis and Critical Control Point) certified plant as well as being certified Organic.  The primary focus of the business has been contracting and processing high quality food grade soybeans that are carefully processed, packaged and exported around the world. CCA is very fortunate to have a dedicated and hardworking staff who focus daily on producing the highest quality product for our customers.  In addition to an amazing staff, CCA has a core group of experienced growers in our area.  These growers take pride in delivering top notch beans that
Ontario is famous for world wide.  CCA underwent a major expansion in 2019! In 2017 azuki beans were introduced into our portfolio of products.`,
        `As grower and end user support grew, we decided to build a new, state of the art plant that processes only dry beans, such as Azuki, Dark Red Kidneys, White kidneys, Otebos and Black Turtle Beans. Our soybean plant still continues receiving and processing conventional and organic soybeans and the new segregated plant and receiving area focuses solely on drybeans.`
    ],
    emailList: [
        "grow@ccacres.ca"
    ],
    joinUs: true,
    showHomePageContact: true,
    showHomePageMap: false,
}

export default aboutUs;