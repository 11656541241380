import BusinessLogo from "../../assets/images/logos/coxFarms/coxFarms.png";
import HomepageBackground from "../../assets/images/backgrounds/coxHomePageBackground.jpg";
import OurStoryBackground from "../../assets/images/backgrounds/coxOurStoryBackground.png";

const styles = {
	mainColor: "#7E966A",
	backgroundColor: "#F2F2F2",
	primaryColor: "#7E966A",
	primaryVariant: "#F2F2F2",
	secondaryColor: "#7E966A",
	secondaryVariant: "#7E966A",
	surface: "#FFF",
	transparentMainColor: "rgba(21,111,152,0.9)",
	homeFontColor: "#FFF",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: HomepageBackground,
	cashBidBackground: HomepageBackground,
	ourStoryImg: OurStoryBackground,
};

export default styles;
