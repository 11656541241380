import HomepageBackground from "../../assets/images/backgrounds/ploverMillsHomeBackground.jpg";
import StoryBackground from "../../assets/images/backgrounds/ploverMillsStoryBackground.jpg";
import Logo from "../../assets/images/logos/ploverMills/ploverMills.png";
const styles = {
	mainColor: "#efc42a",
	backgroundColor: "#FFF",
	btnTextColor: '#FFF',
	primaryColor: "#efc42a",
	primaryVariant: "#efc42a",
	secondaryColor: "#efc42a",
	secondaryVariant: "#007A33",
	surface: "#FFF",
	homeFontColor: "#FFF",
	transparentMainColor: "rgba(0,153,102,0.5)",
	isLogoSquared: true,
	logo: Logo,
	homepageBackground: HomepageBackground,
	ourStoryImg: StoryBackground,
};

export default styles;
